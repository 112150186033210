import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {
  getAllUsers,
  getTotalPages,
  getRoles,
  updateUserDetail,
  resetPassword,
} from '../../../actions/users';
import Icofont from 'react-icofont';

function AllUsers({
  toggling: { toggleNavbarBurger },
  users: { all_users, all_users_count, roles },
  getAllUsers,
  getTotalPages,
  getRoles,
  updateUserDetail,
  resetPassword,
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });
  useEffect(() => {
    getTotalPages();
    getAllUsers('');
    getRoles();
  }, []);

  useEffect(() => {
    // if (all_user_count.total !== null) {
    setPagination({
      ...pagination,
      total: all_users_count?.total,
    });
    // }
  }, [all_users_count]);
  const [state, setstate] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const [searchCheck, setSearchCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  let searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            // type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<SearchOutlined />}
            size='small'
            className='btn btn-gradient-01 btn-custom'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
            className='btn-custom2'>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            style={{ color: 'black' }}
            onClick={() => {
              confirm({ closeDropdown: false });
              setstate({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, dataIndex);
    setLoading(true);
    setSearchCheck(true);
    // confirm();
    const res = await getAllUsers(`?${dataIndex}=${selectedKeys[0]}`);

    if (res) {
      setLoading(false);
      //   setPagination({
      //     ...pagination,
      //     total:
      //   });
    } else {
      setLoading(false);
    }

    // setstate({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setstate({ searchText: '' });
    setSearchCheck(false);
    handleTableChange(pagination);
  };

  const onChangeAccountStatus = async (e, id, role) => {
    setLoading(true);

    let sendData = new FormData();

    sendData.append('account_status', e);
    sendData.append('role', role);

    const res = await updateUserDetail(id, sendData, 'account_status');

    if (res) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onChangeRole = async (e, id, account_status) => {
    setLoading(true);

    let sendData = new FormData();

    sendData.append('role', e);
    sendData.append('account_status', account_status);

    const res = await updateUserDetail(id, sendData, 'role');

    if (res) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      //   width: '30%',
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      // width: '30%',
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      //   width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Payment Status',
      dataIndex: 'subscribed',
      key: 'subscribed',
      //   width: '20%',
      render: (text, record, index) => (
        <Fragment>
          {record.subscribed ? (
            <Tag color='#32CD32'>SUBSCRIBED</Tag>
          ) : (
            <Tag color={'grey'}>UNSUBSCRIBED</Tag>
          )}
        </Fragment>
      ),
      // {
      //   // // // console.log(record.subscribed);
      //   //   if (record.subscribed) {
      //   //     return 'Subscribed';
      //   //   } else {
      //   //     return 'unSubscribed';
      //   //   }
      //   // return record.subscribed.toString();
      // },
      //   ...getColumnSearchProps('subscribed'),
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      //   ...getColumnSearchProps('role'),
      render: (text, record, index) => (
        <Fragment>
          {/* <Link
              to={'/detail/' + record._id}
              className='btn btn-danger'
              style={{ marginRight: '5px' }}
            >
              <Icofont icon='edit' size='2' />
            </Link> */}
          <select
            name=''
            id=''
            defaultValue={record.role.id}
            onChange={(e) =>
              onChangeRole(e.target.value, record.id, record.account_status)
            }
            key={record.id}>
            {roles !== null ? (
              <Fragment>
                {roles.map((role, i) => (
                  <option value={role.id} key={role.id}>
                    {role.role}
                  </option>
                ))}
              </Fragment>
            ) : null}
          </select>
          {/* <Link to={'/detail/' + record._id} className='btn btn-gradient-01'>
              <Icofont icon='list' size='2' />
            </Link>
            <button
              className='btn btn-gradient-01'
              onClick={(e) => deleteCourse(e, record._id, record.category_id)}
              style={{ marginLeft: '5px' }}
            >
              <Icofont icon='ui-delete' size='2' />
            </button> */}
        </Fragment>
      ),
    },
    {
      title: 'Account Status',
      dataIndex: 'account_status',
      key: 'account_status',
      render: (text, record, index) => (
        <Fragment>
          <BootstrapSwitchButton
            // onstyle='outline-success'
            // offstyle='outline-danger'
            size='lg'
            checked={record.account_status}
            onlabel='Enabled'
            offlabel='Disabled'
            onChange={(e) =>
              onChangeAccountStatus(e, record.id, record.role.id)
            }
            // disabled={loadingSwitch}
            width='150'
            // style={
            //   loadingSwitch
            //     ? 'switch-disabled'
            //     : 'switch-enable'
            // }
          />
        </Fragment>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => (
        <Fragment>
          <button
            className='btn btn-gradient-01'
            onClick={(e) => resetPassword(record.id)}
            style={{ marginRight: '5px' }}>
            Reset Password
          </button>
        </Fragment>
      ),
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   key: 'address',
    //   ...getColumnSearchProps('address'),
    // },
  ];

  //   state = {
  //     data: [],
  //     pagination: {
  //       current: 1,
  //       pageSize: 10,
  //     },
  //     loading: false,
  //   };

  //   componentDidMount() {
  //     const { pagination } = this.state;
  //     this.fetch({ pagination });
  //   }

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    console.log(pagination);
    const res = await getAllUsers(
      `?page_no=${pagination.current}&limit=${pagination.pageSize}`
    );

    if (res) {
      setPagination({
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
    // this.fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
  };

  //   fetch = (params = {}) => {
  //     this.setState({ loading: true });
  //     reqwest({
  //       url: 'https://randomuser.me/api',
  //       method: 'get',
  //       type: 'json',
  //       data: getRandomuserParams(params),
  //     }).then((data) => {
  //       console.log(data);
  //       this.setState({
  //         loading: false,
  //         data: data.results,
  //         pagination: {
  //           ...params.pagination,
  //           total: 200,
  //           // 200 is mock data, you should read it from server
  //           // total: data.totalCount,
  //         },
  //       });
  //     });
  //   };

  return (
    <Fragment>
      <div
        className={
          toggleNavbarBurger ? 'content-inner' : 'content-inner active'
        }>
        <div className='container-fluid'>
          {/* <!-- Begin Page Header--> */}
          <div className='row'>
            <div className='page-header'>
              <div className='d-flex align-items-center'>
                <h2 className='page-header-title'>User Management</h2>
                {/* <div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="!#"><i className="ti ti-home"></i></a></li>
                                <li className="breadcrumb-item active">Blank</li>
                            </ul>
                        </div>	                             */}
              </div>
            </div>
          </div>
          {/* <!-- End Page Header --> */}
          {/* <!-- Begin Row --> */}

          <div className='row flex-row'>
            <div className='col-xl-12'>
              {/* <!-- Form --> */}
              <div className='widget has-shadow'>
                <div className='widget-header bordered no-actions d-flex align-items-center'>
                  <h4 style={{ width: '100%' }}>All Users</h4>
                </div>

                <div className='widget-body'>
                  <div className='row animated zoomIn'>
                    <div className='col-md-12'>
                      {all_users !== null && roles !== null && !searchCheck ? (
                        <Table
                          columns={columns}
                          dataSource={all_users}
                          loading={loading}
                          pagination={pagination}
                          onChange={(pagination) =>
                            handleTableChange(pagination)
                          }
                          rowKey='index'
                        />
                      ) : all_users !== null &&
                        roles !== null &&
                        searchCheck ? (
                        <Table
                          columns={columns}
                          dataSource={all_users}
                          loading={loading}
                          // pagination={pagination}
                          // onChange={(pagination) =>
                          //   handleTableChange(pagination)
                          // }
                          rowKey='index'
                        />
                      ) : (
                        <div
                          style={{
                            textAlign: 'center',
                          }}>
                          <div>
                            {/* <span className='icofont-spinner'></span> */}
                            <Icofont icon='spinner' size='3' spin='true' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Form --> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

AllUsers.propTypes = {
  toggling: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  updateUserDetail: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  toggling: state.toggling,
  users: state.users,
});

export default connect(mapStateToProps, {
  getAllUsers,
  getTotalPages,
  getRoles,
  updateUserDetail,
  resetPassword,
})(AllUsers);

import {
  ADMIN_API_ENDPOINT,
  UPDATE_VIDEOS,
  LOADING_VIDEOS,
  AUTH_ERROR,
  DELETE_VIDEO,
  SET_PROGRESS,
  UPDATE_VIDEOS_DETAILS,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// CREATE Video

export const createVideoForSingleLesson = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (ev) => {
      const progress = (ev.loaded / ev.total) * 100;
      //   // // // console.log(ev.loaded, ev.total, progress);
      dispatch({
        type: SET_PROGRESS,
        payload: Math.round(progress),
      });
    },
  };

  try {
    dispatch({
      type: SET_PROGRESS,
      payload: 0,
    });
    dispatch({
      type: LOADING_VIDEOS,
      payload: true,
    });

    const res = await axios.post(`${ADMIN_API_ENDPOINT}/files/`, data, config);

    // // // console.log('Create Video For Single Lesson Response: ', res.data);

    dispatch({
      type: UPDATE_VIDEOS,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Video Created',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    // return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });

    return true;
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('Course Video Error: ', error.message);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
      dispatch({
        type: LOADING_VIDEOS,
        payload: false,
      });

      return false;
    } else {
      // // // console.log('Course Video Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Server Not Responding',
        // timeout: 3000,
      }).show();
      dispatch({
        type: LOADING_VIDEOS,
        payload: false,
      });
      return false;
    }
    // // // // console.log('Create Video For Single Lesson  error: ', error);

    // new Noty({
    //   type: 'error',
    //   layout: 'bottomCenter',
    //   text: 'Video Not Created',
    //   timeout: 3000,
    // }).show();
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// edit video title and description

export const editVideoForSingleLesson = (data, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    //   onUploadProgress: (ev) => {
    //     const progress = (ev.loaded / ev.total) * 100;
    //     //   // // // console.log(ev.loaded, ev.total, progress);
    //     dispatch({
    //       type: SET_PROGRESS,
    //       payload: Math.round(progress),
    //     });
    //   },
  };

  try {
    //   dispatch({
    //     type: SET_PROGRESS,
    //     payload: 0,
    //   });
    //   dispatch({
    //     type: LOADING_VIDEOS,
    //     payload: true,
    //   });

    const res = await axios.put(
      `${ADMIN_API_ENDPOINT}/files/${id}/`,
      data,
      config
    );

    // // // console.log('Edit Video For Single Lesson Response: ', res.data);

    dispatch({
      type: UPDATE_VIDEOS_DETAILS,
      payload: {
        id: id,
        data: {
          title: data.get('title'),
          video_description: data.get('video_description'),
        },
      },
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Video Title and Description Updated',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    // return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });

    return true;
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('Course Video Error: ', error.message);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
      dispatch({
        type: LOADING_VIDEOS,
        payload: false,
      });

      return false;
    } else {
      // // // console.log('Course Video Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Server Not Responding',
        // timeout: 3000,
      }).show();
      dispatch({
        type: LOADING_VIDEOS,
        payload: false,
      });
      return false;
    }
    // // // // console.log('Create Video For Single Lesson  error: ', error);

    // new Noty({
    //   type: 'error',
    //   layout: 'bottomCenter',
    //   text: 'Video Not Created',
    //   timeout: 3000,
    // }).show();
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// Delete video

export const delete_video = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_VIDEOS,
    });

    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/files/${id}/?lesson_id=${data.get(
        'lesson_id'
      )}&course_id=${data.get('course_id')}`
    );

    // // // console.log('delete video Response: ', res.data);

    let check = await dispatch({
      type: DELETE_VIDEO,
      payload: id,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Video Deleted',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    // // // console.log('sadasdsad', check);
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete Video Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Network Error',
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Delete Video Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// Reordering Videos

export const reorderingVideos =
  (data, lesson_id, course_id) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const sendData = {
      lesson_id: lesson_id,
      course_id: course_id,
      videos: data,
    };

    try {
      const res = await axios.patch(
        `${ADMIN_API_ENDPOINT}/files/reordering/`,
        sendData,
        config
      );

      // // // console.log('Reordering Videos Response: ', res.data);

      //   dispatch({
      //     type: CREATE_QUIZ,
      //     payload: res.data,
      //   });

      dispatch({
        type: UPDATE_VIDEOS,
        payload: res.data,
      });

      // new Noty({
      //   type: 'success',
      //   layout: 'bottomCenter',
      //   text: 'Notification Send',
      //   timeout: 3000,
      // }).show();

      //   dispatch({
      //     type: GET_COURSES,
      //     payload: res.data,
      //   });

      // if (res.data.description )
      return true;
    } catch (error) {
      if (error.message !== 'Network Error') {
        // // // console.log('Reordering Videos Network Error: ', error.response.status);
        if (error.response.status === 401) {
          dispatch({
            type: AUTH_ERROR,
          });
        } else {
          new Noty({
            type: 'error',
            layout: 'bottomCenter',
            text: error.response.data.description,
            timeout: 3000,
          }).show();
        }
      } else {
        // // // console.log('Reordering Videos Error: ', error);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }

      return false;
    }
  };

// export const ADMIN_API_ENDPOINT = 'http://192.168.100.193/adminFunctions';
// export const LOGIN_API_ENDPOINT = 'http://192.168.100.193/loginAndRegister';
// export const ADMIN_API_ENDPOINT = 'http://35.160.233.37/adminFunctions';
// export const LOGIN_API_ENDPOINT = 'http://35.160.233.37/loginAndRegister';
export const ADMIN_API_ENDPOINT = 'https://getloop.us/adminFunctions';
export const LOGIN_API_ENDPOINT = 'https://getloop.us/loginAndRegister';
export const IMAGE_URL_ENDPOINT = 'https://test.suhaibwebb.org';

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';

export const SET_NAVBAR_TOGGLE = 'SET_NAVBAR_TOGGLE';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';

export const GET_COURSES = 'GET_COURSES';
export const LOAD_COURSES = 'LOAD_COURSES';
export const CREATE_COURSE = 'CREATE_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const CLEAR_COURSES_MEMORY = 'CLEAR_COURSES_MEMORY';

export const GET_LESSONS = 'GET_LESSONS';
export const GET_SINGLE_LESSON_DETAIL = 'GET_SINGLE_LESSON_DETAIL';
export const GET_SINGLE_LESSON_DETAILS_LOADING =
  'GET_SINGLE_LESSON_DETAILS_LOADING';

export const GET_SINGLE_LESSON_VIDEOS = 'GET_SINGLE_LESSON_VIDEOS';

export const DELETE_LESSON = 'DELETE_LESSON';
export const EDIT_LESSON = 'EDIT_LESSON';
export const LOADING_LESSON = 'LOADING_LESSON';
export const EDIT_SWITCH_STATUS = 'EDIT_SWITCH_STATUS';

export const UPDATE_VIDEOS = 'UPDATE_VIDEOS';
export const LOADING_VIDEOS = 'LOADING_VIDEOS';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const UPDATE_VIDEOS_DETAILS = 'UPDATE_VIDEOS_DETAILS';

export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const LOADING_CONTENT = 'LOADING_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';

export const CREATE_QUIZ = 'CREATE_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const GET_SINGLE_QUIZ = 'GET_SINGLE_QUIZ';
export const UPDATE_QUIZ_LOADING = 'UPDATE_QUIZ_LOADING';
export const LOADING_CREATE_QUIZ = 'LOADING_CREATE_QUIZ';

export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';
export const GET_SINGLE_ASSIGNMENT = 'GET_SINGLE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT_LOADING = 'UPDATE_ASSIGNMENT_LOADING';
export const LOADING_CREATE_ASSIGNMENT = 'LOADING_CREATE_ASSIGNMENT';

export const EDIT_COURSE_NAME = 'EDIT_COURSE_NAME';
export const EDIT_THUMBNAIL = 'EDIT_THUMBNAIL';
export const EDIT_BANNER = 'EDIT_BANNER';
export const EDIT_DESCRIPTION = 'EDIT_DESCRIPTION';

export const SET_PROGRESS = 'SET_PROGRESS';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_COUNT = 'GET_ALL_USERS_COUNT';
export const GET_ROLES = 'GET_ROLES';
export const UPDATE_USER = 'UPDATE_USER';

import {
  ADMIN_API_ENDPOINT,
  AUTH_ERROR,
  GET_ALL_USERS,
  GET_ALL_USERS_COUNT,
  GET_ROLES,
  UPDATE_USER,
} from './types';
import axios from 'axios';
import Noty from 'noty';

//   Get All Users

export const getAllUsers = (query) => async (dispatch) => {
  const data = [
    {
      id: '36b59a4a-6580-4152-9355-55ca8badcfe7',
      first_name: 'Daniyal',
      last_name: 'Suri',
      email: 'daniyal@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '531ea4e0-60da-478d-a86d-f4476a83500e',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad6@codexnow.com',
      subscribed: false,
      role: {
        id: 4,
        role: 'scholarship',
        details: 'Scholarship Role',
      },
      account_status: false,
    },
    {
      id: '79a88df7-fa7e-4089-92cf-5a1adbc95db9',
      first_name: 'Ahmed',
      last_name: 'Khatib',
      email: 'ahmed@khatib.is',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: 'bbecf333-5131-4e20-b34e-1ad334165698',
      first_name: 'Codex',
      last_name: 'eleven',
      email: 'hammad1112@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '55c51f28-779a-4981-ba80-337ccce12d12',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad6@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: 'a95d6fc1-de20-4cbf-84c6-73abb2896cf4',
      first_name: 'Hhh',
      last_name: 'Hhh',
      email: 'jjj@jj.ksks',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '4b254559-3ef1-48a3-b4e2-9f2629538174',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad6@codexnow.com',
      subscribed: false,
      role: {
        id: 4,
        role: 'scholarship',
        details: 'Scholarship Role',
      },
      account_status: false,
    },
    {
      id: 'be0c693a-4764-49a4-9740-c0f0d8cacf37',
      first_name: 'Abdullah Ahmed',
      last_name: 'Ghaznavi',
      email: 'abdullah@codexnow.com',
      subscribed: false,
      role: {
        id: 4,
        role: 'scholarship',
        details: 'Scholarship Role',
      },
      account_status: false,
    },
    {
      id: 'd8a5023b-bc53-4478-9de7-530bcec64705',
      first_name: 'Bdndnd',
      last_name: 'Nnjdjd',
      email: 'hsjdj@jdj.ccc',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '64e5ffcb-a472-46cd-bdd3-437ffdf405f8',
      first_name: 'Jazzy',
      last_name: 'Khadim',
      email: 'jhanzaibkhadim1995@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'f41af78e-1ebf-446f-a9de-66d736066da0',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad1@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '0152100c-03db-4c3a-866d-c90a1a4c3a7e',
      first_name: 'Ahmed',
      last_name: 'Khatib',
      email: 'ahmed@suhaibwebb.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '353b12e5-29a0-4e2e-8036-f27dc4f515ca',
      first_name: 'Naimat',
      last_name: 'Ali',
      email: 'naimat@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '2ff6a081-828c-4173-9a4e-3a94b8c30b45',
      first_name: 'Codex',
      last_name: '',
      email: 'hammad2@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'c252fbbc-5992-4164-8f0c-a944c356f324',
      first_name: 'Dhr',
      last_name: 'Dbrj',
      email: 'ab@ah.ah',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: 'e51683b1-5374-481d-b5b5-06e31338a084',
      first_name: 'Ahmed',
      last_name: 'Khatib',
      email: 'info@kazi.us',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '20a73921-a56f-4e99-b08d-5212e9cce1e9',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad3@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '1cb0c761-e75b-42da-8d7f-81197b65136b',
      first_name: 'Hani',
      last_name: 'Jamil',
      email: 'hani123@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '247e6c4f-8ad4-4e31-ba9a-65391e9446a4',
      first_name: 'Will',
      last_name: 'Webb',
      email: 'sw@suhaibwebb.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '18117b97-94c8-44a5-926c-f9abbf447602',
      first_name: 'Asd',
      last_name: 'Fgh',
      email: 'saad@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '01b871e1-7c9f-451f-b39b-d616cfbe8d80',
      first_name: 'S',
      last_name: 'Wfe',
      email: 'as@wf.cfy',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: 'd4ea84db-b0ea-406c-9aa3-45e66a8d89d9',
      first_name: 'Qwe',
      last_name: 'Rty',
      email: 'uio@pqw.rty',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '63425947-5d14-4561-874c-98e3ff637a3e',
      first_name: 'Sff',
      last_name: 'Fjtjrrk',
      email: 'qwe@qwe.qwe',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '5677df43-e77e-4d13-883e-d446222427fb',
      first_name: 'Dht',
      last_name: 'Bfht',
      email: 'af@rvrv.rgr',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '7ad159f5-f476-4b63-806e-95ab385b25e2',
      first_name: 'Efe',
      last_name: 'Fbtj',
      email: 'aaa@sfd.aaa',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '4d1e6230-359e-425d-ad92-23d30d55c348',
      first_name: 'Jazzy',
      last_name: 'Oh',
      email: 'jazzy@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: false,
    },
    {
      id: '14026c97-0086-480f-9f5d-d67f98022e65',
      first_name: '',
      last_name: '',
      email: 'hammad4@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '8d9e61eb-2c9a-4863-bfe4-aedd1f21c17b',
      first_name: 'Farrukh',
      last_name: 'Zamir',
      email: 'farrukhzamir22@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '0962411c-7bee-4738-bd51-ce3e875966ce',
      first_name: 'Babar',
      last_name: 'Wahab',
      email: 'babarwahab2@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'f44b584e-1acb-404b-ae1d-266c7377a6c5',
      first_name: 'Muhammad',
      last_name: 'Hammad',
      email: 'mhammad1@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '8001fb1f-bcbe-4868-8d1e-e51fb00da3bb',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad8@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '59123d71-f470-470d-acde-bbb2230beee1',
      first_name: 'Amin',
      last_name: 'Karani',
      email: 'amin@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '7baa10d2-e191-4a56-aa0b-b1c6d7daf978',
      first_name: 'Codex',
      last_name: 'Test',
      email: 'hammad9@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '484d7e46-8f1c-4e41-b0e3-844678e3db46',
      first_name: 'kawish',
      last_name: 'Hussain',
      email: 'kawish@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'f0cc66ce-4274-49db-8523-329e762032f0',
      first_name: 'Owais',
      last_name: 'Khan',
      email: 'hammad19@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'ba4077ac-c909-493c-9b8a-eaa7baaf3dee',
      first_name: 'Muhammad',
      last_name: 'Hammad',
      email: 'mhammad@codexnow.com',
      subscribed: false,
      role: {
        id: 4,
        role: 'scholarship',
        details: 'Scholarship Role',
      },
      account_status: true,
    },
    {
      id: 'fba79b3e-c17b-44e6-8e7e-d0205acedca1',
      first_name: 'Owais',
      last_name: 'Khan',
      email: 'hammad20@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'd26510b1-f633-4bf0-802a-5409ac28fbc6',
      first_name: 'Masood',
      last_name: 'Khan',
      email: 'masood@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'fd1c605e-a177-4975-8008-37f99e9cfda9',
      first_name: 'Saad',
      last_name: 'Ahmed',
      email: 'saad123@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'f0a78289-cfcd-4297-97b0-d26738a77be4',
      first_name: 'Anwar',
      last_name: 'Haque',
      email: 'mailtoauhaque@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'eb8ae5e7-8303-41dd-a9e7-054d509df76d',
      first_name: 'A',
      last_name: 'K',
      email: 'ak@khatib.us',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '883c678d-134e-4a59-abfa-5999a0d711a7',
      first_name: 'Codex',
      last_name: 'eleven',
      email: 'hammad112@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '0008ff45-e2f3-4c02-af58-263a2ac7bca0',
      first_name: 'Codexx',
      last_name: 'Test',
      email: 'hammad@codexnow.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '45d7ff02-ce85-474d-a55d-ec631f2b5b25',
      first_name: 'Hani',
      last_name: 'Jamil',
      email: 'hani@codexnow.com',
      subscribed: true,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '42f6a619-ba16-4ca1-8185-a1d75f2dfa4f',
      first_name: 'Ahmed',
      last_name: 'Khatib',
      email: 'Ahmedk@ahmedkhatib.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'a4429d42-e14f-45ec-84a5-db1030b43efa',
      first_name: 'Suhaib',
      last_name: 'Webb',
      email: 'swiss@suhaibwebb.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '927950ff-5719-42ac-a2bf-914a7d6f7ccd',
      first_name: 'Anwar',
      last_name: 'Haque',
      email: 'anwar.dsu@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '4ac470cd-1bd2-4f08-8d7e-feaa0e572fe0',
      first_name: 'Hani',
      last_name: 'Jamil',
      email: 'modesthani08@gmail.com',
      subscribed: true,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '648ab8f2-ac59-4dbf-a682-f6c9379f7561',
      first_name: 'Hani',
      last_name: 'Jamil',
      email: 'modesthani08@outlook.com',
      subscribed: true,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '3c12bcd1-2f3a-462b-9dfb-644316f1cf66',
      first_name: 'Jazzy',
      last_name: 'Khadim',
      email: 'jhanzaibkhadim1994@gmail.com',
      subscribed: true,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '48973759-06a2-4cab-aade-8c2b1fb95ea7',
      first_name: 'Anwar',
      last_name: 'Haque',
      email: 'nazim@codexnow.com',
      subscribed: true,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: '722bf495-56eb-4ab8-aa55-fdc164f998c9',
      first_name: 'saabira',
      last_name: 'vanker',
      email: 'saabira.vanker@gmail.com',
      subscribed: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
    {
      id: 'aedd51e1-a2cf-437e-883b-133c38998eed',
      first_name: 'Anwar',
      last_name: 'Haque',
      email: 'ahaque@iba.edu.pk',
      payment_status: false,
      role: {
        id: 2,
        role: 'student',
        details: 'Student Role',
      },
      account_status: true,
    },
  ];

  try {
    const res = await axios.get(
      `${ADMIN_API_ENDPOINT}/user/management/${query}`
    );

    // // // console.log('Get All Users Data: ', res.data);

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });

    return true;
  } catch (error) {
    // // // console.log('Get All Users Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('All Users List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('All Users List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('All Users Error: ', error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  }
};

// Get Total Count Of All Users Record

export const getTotalPages = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/user/count/`);

    console.log('Get All Users Count Data: ', res.data);

    dispatch({
      type: GET_ALL_USERS_COUNT,
      payload: res.data,
    });

    return true;
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('All Users List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('All Users List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('All Users Error: ', error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  }
};

// GET Roles

export const getRoles = () => async (dispatch) => {
  const roles = [
    {
      id: 2,
      role: 'Student',
      details: 'Student Role',
    },
    {
      id: 1,
      role: 'Scholarship',
      details: 'Student Role',
    },
    {
      id: 3,
      role: 'Admin',
      details: 'Student Role',
    },
    {
      id: 4,
      role: 'Super Admin',
      details: 'Student Role',
    },
  ];
  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/user/roles/`);

    // // // console.log('Get Roles Data: ', res.data);

    dispatch({
      type: GET_ROLES,
      payload: res.data,
    });

    return true;
  } catch (error) {
    // // // console.log('Get All Users Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('All Users List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('All Users List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('All Users Error: ', error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  }
};

// Update User Details

export const updateUserDetail = (user_id, data, type) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${ADMIN_API_ENDPOINT}/user/management/${user_id}/`,
      data
    );

    // // // console.log('Update All Users Data: ', res.data);

    dispatch({
      type: UPDATE_USER,
      payload: { ...res.data, type_check: type },
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: type + ' Updated',
      timeout: 3000,
    }).show();

    return true;
  } catch (error) {
    // // // console.log('Get All Users Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('All Users List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('All Users List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('All Users Error: ', error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  }
};

// Reset password

export const resetPassword = (user_id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/user/management/${user_id}/`
    );

    // // // console.log('reset password Data: ', res.data);

    //   dispatch({
    //     type: UPDATE_USER,
    //     payload: { ...res.data, type_check: type },
    //   });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Password Reset SuccessFull',
      timeout: 3000,
    }).show();

    return true;
  } catch (error) {
    // // // console.log('Get All Users Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('All Users List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('All Users List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('All Users Error: ', error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  }
};

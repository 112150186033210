import React, { useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/vehicleManagement.css';

import AddCourse from './CourseManagement/AddCourse';
import ViewCourse from './CourseManagement/ViewCourse';
// import TestingReordering from './CourseManagement/TestingReordering';

function AddCar({ toggling: { toggleNavbarBurger } }) {
  //   const [data, setData] = useState({
  //     name: '',
  //     number: '',
  //     model: '',
  //     color: '',
  //   });

  const [toggleForm, setToggleForm] = useState(false);

  //   const onChangeFields = (e) => {
  //     e.preventDefault();

  //     setData({ ...data, [e.target.name]: e.target.value });
  //   };

  //   const onSubmitForm = (e) => {
  //     e.preventDefault();

  //     // // // console.log('Add Car Data: ', data);
  //   };

  const toggleAddVehicleForm = (e) => {
    e.preventDefault();

    setToggleForm(!toggleForm);
  };

  const onSubmitToggleChange = (e) => {
    e.preventDefault();

    setToggleForm(false);
  };

  //   const onResetForm = (e) => {
  //     e.preventDefault();

  //     setData({
  //       name: '',
  //       number: '',
  //       model: '',
  //       color: '',
  //     });
  //   };

  return (
    <div
      className={toggleNavbarBurger ? 'content-inner' : 'content-inner active'}
    >
      <div className='container-fluid'>
        {/* <!-- Begin Page Header--> */}
        <div className='row'>
          <div className='page-header'>
            <div className='d-flex align-items-center'>
              <h2 className='page-header-title'>Course Management</h2>
              {/* <div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="!#"><i className="ti ti-home"></i></a></li>
                                <li className="breadcrumb-item active">Blank</li>
                            </ul>
                        </div>	                             */}
            </div>
          </div>
        </div>
        {/* <!-- End Page Header --> */}
        {/* <!-- Begin Row --> */}

        <div className='row flex-row'>
          <div className='col-xl-12'>
            {/* <!-- Form --> */}
            <div className='widget has-shadow'>
              <div className='widget-header bordered no-actions d-flex align-items-center'>
                <h4 style={{ width: '50%' }}>All Courses</h4>
                <div className='text-right' style={{ width: '50%' }}>
                  <button
                    onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    {toggleForm ? 'Back To Courses' : 'Add Course'}
                    {/* Add Vehicle */}
                  </button>
                </div>
              </div>

              <div className='widget-body'>
                {toggleForm ? (
                  <AddCourse changeView={onSubmitToggleChange} />
                ) : (
                  <ViewCourse />
                  //   <TestingReordering />
                )}
              </div>
            </div>
            {/* <!-- End Form --> */}
          </div>
        </div>

        {/* <div className="row flex-row">
                <div className="col-xl-12 col-12">
                    <div className="widget has-shadow">
                        <div className="widget-body">
                            <p className="text-primary mt-2 mb-2">Here Comes Form :)</p>
                        </div>
                    </div>
                </div>
            </div> */}
        {/* <!-- End Row --> */}
      </div>
      {/* <!-- End Container --> */}
      {/* <!-- Begin Page Footer--> */}
      {/* <footer className="main-footer fixed-footer">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-center">
                    <p className="text-gradient-02">Design By Saerox</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" href="!#">Documentation</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="!#">Changelog</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer> */}
      {/* <!-- End Page Footer --> */}
      {/* <a href='!#' className='go-top'>
        <i className='la la-arrow-up'></i>
      </a> */}
    </div>
  );
}

AddCar.propTypes = {
  toggling: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  toggling: state.toggling,
});

export default connect(mapStateToProps, null)(AddCar);

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCategories, createCourse } from '../../../actions/courses';
// import Loader from 'react-loader-spinner';
import Icofont from 'react-icofont';

function AddCourse({
  courses: { allCategories },
  getCategories,
  changeView,
  createCourse,
}) {
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const [filteredCategories, setFilteredCategories] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [bannerImageData, setBannerImageData] = useState(null);
  const [webImageData, setWebImageData] = useState(null);
  const [toggleLoader, setToggleLoader] = useState(false);

  const [data, setData] = useState({
    name: '',
    category: '',
    type: '',
    description: '',
    // color: '',
  });

  const onChangeFields = (e) => {
    e.preventDefault();

    let filteredData = allCategories.filter(
      (data) => data.type === e.target.value
    );

    // // // // console.log(filteredData);

    if (e.target.name === 'type') {
      setFilteredCategories(filteredData[0]);
      setData({ ...data, category: '', [e.target.name]: e.target.value });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();
    // // // console.log('file Data: ', e.target.files[0]);

    if (e.target.name === 'thumbnailImage') {
      setFileData(e.target.files[0]);
    } else if (e.target.name === 'webImage') {
      setWebImageData(e.target.files[0]);
    } else {
      setBannerImageData(e.target.files[0]);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    setToggleLoader(true);

    let dataForm = new FormData();

    dataForm.append('name', data.name);
    dataForm.append('category', data.category);
    dataForm.append('description', data.description);
    dataForm.append('image', fileData);
    dataForm.append('banner_image', bannerImageData);
    dataForm.append('web_image', webImageData !== null ? webImageData : '');

    // // // // console.log(dataForm);

    // for (var value of dataForm.values()) {
    //   // // // console.log(value);
    // }

    let sendData = await createCourse(dataForm);

    if (sendData === true) {
      setToggleLoader(false);
      changeView(e);
    } else {
      setToggleLoader(false);
    }

    // // // // console.log('Add Course Data: ', data);
  };

  const onResetForm = (e) => {
    e.preventDefault();

    // setFileData(null);
    document.getElementById('myFile').value = null;
    document.getElementById('myFileBanner').value = null;
    document.getElementById('myFileWebImage').value = null;
    setFilteredCategories(null);
    setData({
      name: '',
      category: '',
      type: '',
      description: '',
    });
  };

  return (
    <Fragment>
      {allCategories !== null ? (
        <form
          className='needs-validation animated zoomIn'
          onSubmit={(e) => onSubmitForm(e)}
          onReset={(e) => onResetForm(e)}
        >
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Name *
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='name'
                value={data.name}
                onChange={(e) => onChangeFields(e)}
                className='form-control'
                placeholder='Name'
                required='required'
              />
              {/* <div className="invalid-feedback">
                                                         Please enter a valid password
                                                     </div> */}
            </div>
          </div>
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Description *
            </label>
            <div className='col-lg-5'>
              <textarea
                type='text'
                name='description'
                value={data.description}
                onChange={(e) => onChangeFields(e)}
                className='form-control'
                placeholder='Description'
                required='required'
              />
              {/* <div className="invalid-feedback">
                                                         Please enter a valid password
                                                     </div> */}
            </div>
          </div>
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Type *
            </label>
            <div className='col-lg-5'>
              <div className='select'>
                {allCategories !== null ? (
                  <select
                    name='type'
                    className='custom-select form-control'
                    value={data.type}
                    required=''
                    onChange={(e) => onChangeFields(e)}
                  >
                    <option value='' disabled={true}>
                      Select an option
                    </option>
                    {allCategories.map((data, i) => (
                      <option key={i} value={data.type}>
                        {data.type}
                      </option>
                    ))}
                  </select>
                ) : null}

                {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
              </div>
            </div>
          </div>
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Category *
            </label>
            <div className='col-lg-5'>
              <div className='select'>
                {filteredCategories !== null ? (
                  <select
                    name='category'
                    className='custom-select form-control'
                    value={data.category}
                    required=''
                    onChange={(e) => onChangeFields(e)}
                  >
                    <option value='' disabled={true}>
                      Select an option
                    </option>
                    {filteredCategories.category.map((data, i) => (
                      <option key={i} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    name='category'
                    className='custom-select form-control'
                    value={data.category}
                    required=''
                    onChange={(e) => onChangeFields(e)}
                    disabled={true}
                  >
                    <option value='' disabled={true}>
                      Select an option
                    </option>
                  </select>
                )}

                {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
              </div>
            </div>
          </div>

          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Upload Thumbnail Image *
            </label>
            <div className='col-lg-5'>
              <input
                type='file'
                id='myFile'
                // value={fileData}
                // className='form-control'
                name='thumbnailImage'
                onChange={(e) => onFileChange(e)}
              ></input>
              {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
            </div>
          </div>
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Upload Banner Image *
            </label>
            <div className='col-lg-5'>
              <input
                type='file'
                id='myFileBanner'
                // value={fileData}
                // className='form-control'
                name='bannerImage'
                onChange={(e) => onFileChange(e)}
              ></input>
              {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
            </div>
          </div>
          <div className='form-group row d-flex align-items-center mb-5'>
            <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
              Website Image *
            </label>
            <div className='col-lg-5'>
              <input
                type='file'
                id='myFileWebImage'
                // value={fileData}
                // className='form-control'
                name='webImage'
                onChange={(e) => onFileChange(e)}
              ></input>
              {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
            </div>
          </div>
          {/* <div className='form-group row d-flex align-items-center mb-5'>
        <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
          Number *
        </label>
        <div className='col-lg-5'>
          <input
            type='text'
            name='number'
            value={data.number}
            onChange={(e) => onChangeFields(e)}
            className='form-control'
            placeholder='Number'
            required='required'
          />
        </div>
      </div>
      <div className='form-group row d-flex align-items-center mb-5'>
        <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
          Model *
        </label>
        <div className='col-lg-5'>
          <input
            type='text'
            name='model'
            value={data.model}
            onChange={(e) => onChangeFields(e)}
            className='form-control'
            placeholder='Model'
            required='required'
          />
        </div>
      </div>
      <div className='form-group row d-flex align-items-center mb-5'>
        <label className='col-lg-4 form-control-label d-flex justify-content-lg-end'>
          Color *
        </label>
        <div className='col-lg-5'>
          <input
            type='text'
            name='color'
            value={data.color}
            onChange={(e) => onChangeFields(e)}
            className='form-control'
            placeholder='Color'
            required='required'
          />
        </div>
      </div> */}

          <div className='text-right'>
            <button
              className='btn btn-gradient-01'
              type='submit'
              style={toggleLoader ? { pointerEvents: 'none' } : null}
            >
              <span style={toggleLoader ? { marginRight: '10px' } : null}>
                Submit Form
              </span>
              {/* <Icofont icon='spinner' size='3' spin='true' /> */}
              {toggleLoader ? (
                // <Loader
                //   type='Oval'
                //   color='white'
                //   height={20}
                //   width={20}
                //   // timeout={3000} //3 secs
                //   // visible={toggleLoader ? true : false}
                // />
                <Icofont icon='spinner' size='3' spin='true' />
              ) : null}
            </button>
            <button className='btn btn-shadow' type='reset'>
              Reset
            </button>
          </div>
        </form>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Icofont icon='spinner' size='3' spin='true' />
        </div>
      )}

      {/* {toggleLoader ? (
        <Loader
          type='Circles'
          color='#00BFFF'
          height={50}
          width={50}
          // timeout={3000} //3 secs
        />
      ) : null} */}
    </Fragment>
  );
}

AddCourse.propTypes = {
  toggling: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  changeView: PropTypes.func.isRequired,
  createCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  toggling: state.toggling,
  courses: state.courses,
});

export default connect(mapStateToProps, { getCategories, createCourse })(
  AddCourse
);

import React, { Fragment, useState } from 'react';
import loop_logo from '../../assets/img/loop_logo.png';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  userAuthentication,
  userRegistration,
  userAuthenticationWithOtp,
} from '../../actions/auth';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import Noty from 'noty';

function LoginRegister({
  auth: { isAuthenticated },
  userAuthentication,
  userAuthenticationWithOtp,
  userRegistration,
  history,
}) {
  const [toggleSignIn, setToggleSignIn] = useState(true);

  const [toggleOtp, setToggleOtp] = useState(false);

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [otp, setOtp] = useState('');

  const [toggleLoader, setToggleLoader] = useState(false);

  const [toggleLoaderOtp, setToggleLoaderOtp] = useState(false);

  const [registerData, setRegisterData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { first_name, last_name, email, password } = registerData;

  const changeSignIn = (e) => {
    e.preventDefault();

    // // // console.log(e.target.name, toggleSignIn);

    if (e.target.name === 'signin') {
      setToggleSignIn(true);
    } else {
      setToggleSignIn(false);
    }
  };

  const saveLoginData = (e) => {
    e.preventDefault();

    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const saveRegisterData = (e) => {
    e.preventDefault();

    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const login = async (e) => {
    e.preventDefault();
    setToggleLoader(true);
    let check = await userAuthentication(loginData, history);

    if (check) {
      setToggleLoader(false);
      setToggleOtp(true);
      setToggleSignIn(false);
    } else {
      setToggleLoader(false);
    }

    // // // console.log('Login Button Clicked! ');
  };

  const loginWithOtp = async (e) => {
    e.preventDefault();
    setToggleLoaderOtp(true);

    const sendData = new FormData();

    sendData.append('device_id', '');
    sendData.append('email', loginData.email);
    sendData.append('otp', otp);
    let check = await userAuthenticationWithOtp(sendData, history);

    if (check) {
      setToggleLoaderOtp(false);
      setToggleOtp(false);
      setOtp('');
    } else {
      setToggleLoaderOtp(false);
      //   setToggleOtp(false);
    }

    // // // console.log('Login Otp Button Clicked! ');
  };

  const register = async (e) => {
    e.preventDefault();
    setToggleLoader(true);
    if (registerData.password !== registerData.confirmPassword) {
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Password Not Matched',
        timeout: 1500,
      }).show();
      setToggleLoader(false);
    } else {
      let sendData = {
        first_name,
        last_name,
        email,
        password,
        role: 'Admin',
      };
      let check = await userRegistration(sendData);

      if (check) {
        setToggleLoader(false);
        setToggleSignIn(true);

        setRegisterData({
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          confirmPassword: '',
        });
      } else {
        setToggleLoader(false);
      }
    }

    // // // console.log('Register Button Clicked! ');
  };

  // Redirect If Looged in

  if (localStorage.token) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      {/* <!-- Begin Preloader --> */}
      {/* <div id="preloader">
        <div className="canvas">
          <img src={Logo} alt="logo" className="loader-logo" />
          <div className="spinner"></div>
        </div>
      </div> */}
      {/* <!-- End Preloader --> */}
      {/* <!-- Begin Container --> */}
      <div className='container-fluid no-padding h-100'>
        <div className='row flex-row h-100 bg-white'>
          {/* <!-- Begin Left Content --> */}
          <div className='col-xl-3 col-lg-5 col-md-5 col-sm-12 col-12 no-padding'>
            <div className='elisyam-bg'>
              <div
                className='elisyam-overlay overlay-08 bg-theme-color'
                style={{ background: 'var(--blue)' }}></div>
              <div className='authentication-col-content-2 mx-auto text-center'>
                <div className='logo-centered'>
                  <Link to='/'>
                    <img src={loop_logo} alt='logo' />
                  </Link>
                </div>
                {/* <h1>Welcome To Rent A Car Online Portal!</h1> */}
                {/* <span className="description">
                  Etiam consequat urna at magna bibendum, in tempor arcu
                  fermentum vitae mi massa egestas.
                </span> */}
                <ul
                  className='login-nav nav nav-tabs mt-5 justify-content-center'
                  role='tablist'
                  id='animate-tab'>
                  {!toggleOtp ? (
                    <li>
                      <a
                        className={toggleSignIn ? 'active' : ''}
                        onClick={(e) => changeSignIn(e)}
                        data-toggle='tab'
                        href='!#'
                        name='signin'
                        role='tab'
                        id='singin-tab'
                        data-easein='zoomInUp'>
                        Sign In
                      </a>
                    </li>
                  ) : null}
                  {/* <li>
                    <a
                      className={toggleSignIn ? '' : 'active'}
                      onClick={(e) => changeSignIn(e)}
                      data-toggle='tab'
                      href='!#'
                      name='signup'
                      role='tab'
                      id='signup-tab'
                      data-easein='zoomInRight'
                    >
                      Sign Up
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- End Left Content --> */}
          {/* <!-- Begin Right Content --> */}
          <div className='col-xl-9 col-lg-7 col-md-7 col-sm-12 col-12 my-auto no-padding'>
            {/* <!-- Begin Form --> */}
            <div className='authentication-form-2 mx-auto has-shadow p-5'>
              <div className='tab-content' id='animate-tab-content'>
                {/* <!-- Begin Sign In --> */}
                <div
                  role='tabpanel'
                  className={
                    toggleSignIn
                      ? 'tab-pane show active animated zoomIn'
                      : 'tab-pane'
                  }
                  id='singin'
                  aria-labelledby='singin-tab'>
                  <h3>Sign In To Loop</h3>
                  <form onSubmit={(e) => login(e)}>
                    <div className='group material-input'>
                      <input
                        type='text'
                        name='email'
                        value={loginData.email}
                        onChange={(e) => saveLoginData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Email</label>
                    </div>
                    <div className='group material-input'>
                      <input
                        type='password'
                        name='password'
                        value={loginData.password}
                        onChange={(e) => saveLoginData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Password</label>
                    </div>
                    <div className='row'>
                      {/* <div className='col text-left'>
                        <div className='styled-checkbox'>
                          <input type='checkbox' name='checkbox' id='remeber' />
                          <label htmlFor='remeber'>Remember me</label>
                        </div>
                      </div> */}
                      {/* <div className='col text-right'>
                        <a href='pages-forgot-password.html'>
                          Forgot Password ?
                        </a>
                      </div> */}
                    </div>
                    <div className='sign-btn text-center'>
                      <button
                        // href='!#'
                        onClick={(e) => login(e)}
                        className='btn btn-lg btn-gradient-01'
                        style={toggleLoader ? { pointerEvents: 'none' } : null}>
                        <span
                          style={toggleLoader ? { marginRight: '10px' } : null}>
                          Sign In
                        </span>
                        {/* <Icofont icon='spinner' size='3' spin='true' /> */}
                        {toggleLoader ? (
                          // <Loader
                          //   type='Oval'
                          //   color='white'
                          //   height={20}
                          //   width={20}
                          //   // timeout={3000} //3 secs
                          //   // visible={toggleLoader ? true : false}
                          // />
                          <Icofont icon='spinner' size='3' spin='true' />
                        ) : null}
                      </button>
                    </div>
                  </form>
                </div>
                {/* <!-- End Sign In --> */}
                {/* <!-- Begin OPT --> */}
                <div
                  role='tabpanel'
                  className={
                    toggleOtp
                      ? 'tab-pane show active animated zoomIn'
                      : 'tab-pane'
                  }
                  id='singin'
                  aria-labelledby='singin-tab'>
                  <h3>Two Factor Authentication</h3>
                  <p>Check your email for Two Factor Authentication Code</p>
                  <form onSubmit={(e) => loginWithOtp(e)}>
                    <div className='group material-input'>
                      <input
                        type='text'
                        name='otp'
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Code:</label>
                    </div>
                    <div className='row'>
                      {/* <div className='col text-left'>
                        <div className='styled-checkbox'>
                          <input type='checkbox' name='checkbox' id='remeber' />
                          <label htmlFor='remeber'>Remember me</label>
                        </div>
                      </div> */}
                      {/* <div className='col text-right'>
                        <a href='pages-forgot-password.html'>
                          Forgot Password ?
                        </a>
                      </div> */}
                    </div>
                    <div className='sign-btn text-center'>
                      <button
                        // href='!#'
                        onClick={(e) => loginWithOtp(e)}
                        className='btn btn-lg btn-gradient-01'
                        style={
                          toggleLoaderOtp ? { pointerEvents: 'none' } : null
                        }>
                        <span
                          style={
                            toggleLoaderOtp ? { marginRight: '10px' } : null
                          }>
                          Submit
                        </span>
                        {/* <Icofont icon='spinner' size='3' spin='true' /> */}
                        {toggleLoaderOtp ? (
                          // <Loader
                          //   type='Oval'
                          //   color='white'
                          //   height={20}
                          //   width={20}
                          //   // timeout={3000} //3 secs
                          //   // visible={toggleLoader ? true : false}
                          // />
                          <Icofont icon='spinner' size='3' spin='true' />
                        ) : null}
                      </button>
                    </div>
                  </form>
                </div>
                {/* End OTP */}
                {/* <!-- Begin Sign Up --> */}
                <div
                  role='tabpanel'
                  className={'tab-pane'}
                  //   className={
                  //     toggleSignIn
                  //       ? 'tab-pane'
                  //       : 'tab-pane show active animated zoomIn'
                  //   }
                  id='signup'
                  aria-labelledby='signup-tab'>
                  <h3>Create An Account</h3>
                  <form onSubmit={(e) => register(e)}>
                    <div className='group material-input'>
                      <input
                        type='text'
                        name='first_name'
                        value={registerData.first_name}
                        onChange={(e) => saveRegisterData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>First Name</label>
                    </div>
                    <div className='group material-input'>
                      <input
                        type='text'
                        name='last_name'
                        value={registerData.last_name}
                        onChange={(e) => saveRegisterData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Last Name</label>
                    </div>
                    <div className='group material-input'>
                      <input
                        type='email'
                        name='email'
                        value={registerData.email}
                        onChange={(e) => saveRegisterData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Email</label>
                    </div>
                    <div className='group material-input'>
                      <input
                        type='password'
                        name='password'
                        value={registerData.password}
                        onChange={(e) => saveRegisterData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Password</label>
                    </div>
                    <div className='group material-input'>
                      <input
                        type='password'
                        name='confirmPassword'
                        value={registerData.confirmPassword}
                        onChange={(e) => saveRegisterData(e)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Confirm Password</label>
                    </div>
                    {/* <div className='row'>
                      <div className='col text-left'>
                        <div className='styled-checkbox'>
                          <input type='checkbox' name='checkbox' id='agree' />
                          <label htmlFor='agree'>
                            I Accept <Link to='/'>Terms and Conditions</Link>
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className='sign-btn text-center'>
                      <button
                        onClick={(e) => register(e)}
                        className='btn btn-lg btn-gradient-01'
                        style={toggleLoader ? { pointerEvents: 'none' } : null}>
                        <span
                          style={toggleLoader ? { marginRight: '10px' } : null}>
                          Sign Up
                        </span>
                        {/* <Icofont icon='spinner' size='3' spin='true' /> */}
                        {toggleLoader ? (
                          // <Loader
                          //   type='Oval'
                          //   color='white'
                          //   height={20}
                          //   width={20}
                          //   // timeout={3000} //3 secs
                          //   // visible={toggleLoader ? true : false}
                          // />
                          <Icofont icon='spinner' size='3' spin='true' />
                        ) : null}
                      </button>
                    </div>
                  </form>
                </div>
                {/* <!-- End Sign Up --> */}
              </div>
            </div>
            {/* <!-- End Form -->                         */}
          </div>
          {/* <!-- End Right Content --> */}
        </div>
        {/* <!-- End Row --> */}
      </div>
    </Fragment>
  );
}

LoginRegister.propTypes = {
  userAuthentication: PropTypes.func.isRequired,
  userAuthenticationWithOtp: PropTypes.func.isRequired,
  userRegistration: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  userAuthentication,
  userAuthenticationWithOtp,
  userRegistration,
})(withRouter(LoginRegister));

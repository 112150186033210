import {
  GET_COURSES,
  GET_CATEGORIES,
  ADMIN_API_ENDPOINT,
  LOAD_COURSES,
  AUTH_ERROR,
  EDIT_COURSE_NAME,
  EDIT_THUMBNAIL,
  DELETE_COURSE,
  EDIT_BANNER,
  CLEAR_COURSES_MEMORY,
  EDIT_DESCRIPTION,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// GET ALL COURSES

export const getCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_COURSES,
    });
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/courses/`);

    // // // console.log('Get Courses Data: ', res.data);

    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });

    return true;
  } catch (error) {
    // // // console.log('Get Courses Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('Course Lesson List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('Course Lesson List Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('Course Lesson List Error: ',error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// CLEAR ALL COURSES

export const clearAllCourses = () => async (dispatch) => {
  await dispatch({
    type: CLEAR_COURSES_MEMORY,
  });
};

// CREATE COURSE

export const createCourse = (courseDetail) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  //   // // // console.log('course Detail data: ', courseDetail);

  try {
    const res = await axios.post(
      `${ADMIN_API_ENDPOINT}/courses/`,
      courseDetail,
      config
    );

    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Course Created',
      timeout: 3000,
    }).show();

    // // // console.log('Create Course Response: ', res.data);

    // if (res.data.description )
    return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });
  } catch (error) {
    // // // // console.log('Get Courses Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('Course Lesson List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Course Lesson List Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Network Error',
        timeout: 3000,
      }).show();
    }

    return false;
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// Edit Course Name

export const editCourseName =
  (data, name, courseID, source) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.put(
        `${ADMIN_API_ENDPOINT}/courses/${courseID}/`,
        data,
        config
      );

      // // // console.log('Edit Course Name Response: ', res.data);

      if (source === 'name') {
        dispatch({
          type: EDIT_COURSE_NAME,
          payload: name,
        });

        new Noty({
          type: 'success',
          layout: 'bottomCenter',
          text: 'Course Name Edited',
          timeout: 3000,
        }).show();

        return true;
      } else if (source === 'description') {
        dispatch({
          type: EDIT_DESCRIPTION,
          payload: name,
        });

        new Noty({
          type: 'success',
          layout: 'bottomCenter',
          text: 'Course Description Edited',
          timeout: 3000,
        }).show();

        return true;
      } else if (source === 'banner') {
        dispatch({
          type: EDIT_BANNER,
          payload: res.data.update,
        });

        new Noty({
          type: 'success',
          layout: 'bottomCenter',
          text: 'Course Banner Edited',
          timeout: 3000,
        }).show();

        return true;
      } else {
        dispatch({
          type: EDIT_THUMBNAIL,
          payload: res.data.update,
        });

        new Noty({
          type: 'success',
          layout: 'bottomCenter',
          text: 'Course Image Edited',
          timeout: 3000,
        }).show();

        return true;
      }
    } catch (error) {
      // // // // console.log(error);
      if (error.message !== 'Network Error') {
        // // // console.log('Course Lesson List Error: ', error.response.status);
        if (error.response.status === 401) {
          dispatch({
            type: AUTH_ERROR,
          });
        } else {
          new Noty({
            type: 'error',
            layout: 'bottomCenter',
            text: error.response.data.description,
            timeout: 3000,
          }).show();
        }
      } else {
        // // // console.log('Course Lesson List Error: ', error);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
  };

// Delete Course

export const delete_course = (category_id, course_id) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_COURSES,
    });

    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/courses/${course_id}/?category_id=${category_id}`
    );

    // // // console.log('delete Course Response: ', res.data);

    dispatch({
      type: DELETE_COURSE,
      payload: {
        course_id,
        category_id,
      },
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Course Deleted',
      timeout: 3000,
    }).show();

    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete Course Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Network Error',
          timeout: 3000,
        }).show();
      }
    } else {
      //   // // // console.log('Delete Lesson Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// GET ALL CATEGORIES

export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/category/`);

    // // // console.log('Get Categories Data: ', res.data);

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    // // // console.log('Get Categories Data error: ', error);
    if (error.message !== 'Network Error') {
      // // // console.log('Get Categories Data Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      if (error.message === 'Network Error') {
        // // // console.log('Get Categories Data Error: ', error.status);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Server is not responding',
          timeout: 3000,
        }).show();
      } else {
        // // // console.log('Get Categories Data Error: ',error.response.message.description);

        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    }
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// SEND NOTIFICATION

export const sendNotification = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${ADMIN_API_ENDPOINT}/firebaseNotification/`,
      data,
      config
    );

    // // // console.log('Send Notification Response: ', res.data);

    //   dispatch({
    //     type: CREATE_QUIZ,
    //     payload: res.data,
    //   });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Notification Send',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Send Notification Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Send Notification Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// REORDERING COURSES

export const reorderingCourses = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.patch(
      `${ADMIN_API_ENDPOINT}/courses/reordering/`,
      data,
      config
    );

    // // // console.log('Reordering Courses Response: ', res.data);

    //   dispatch({
    //     type: CREATE_QUIZ,
    //     payload: res.data,
    //   });

    // new Noty({
    //   type: 'success',
    //   layout: 'bottomCenter',
    //   text: 'Notification Send',
    //   timeout: 3000,
    // }).show();

    dispatch({
      type: GET_COURSES,
      payload: res.data,
    });

    // if (res.data.description )
    return true;
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('Reordering Courses Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Reordering Courses Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Network Error',
        timeout: 3000,
      }).show();
    }

    return false;
  }
};

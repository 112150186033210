import React, { Fragment, useEffect, useState } from 'react';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Zoom,
} from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

// import ReactDatatable from '@ashvin27/react-datatable';
// import Image1 from '../../../assets/img/vehicle-images/1.jpg';
// import Image2 from '../../../assets/img/vehicle-images/2.jpg';
// import Image3 from '../../../assets/img/vehicle-images/3.jpg';
// import Image4 from '../../../assets/img/vehicle-images/4.jpg';
// import Image5 from '../../../assets/img/vehicle-images/5.jpg';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCourses,
  delete_course,
  clearAllCourses,
} from '../../../actions/courses';
// import { IMAGE_URL_ENDPOINT } from '../../../actions/types';

// import DetailCourse from './DetailCourse';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import DataTableWithSorting from './DataTableWithSorting';
// import Icofont from 'react-icofont';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom]);

function ViewCourse({
  courses: {
    allCourses,
    // loadingCourses
  },
  getCourses,
  clearAllCourses,
  //   delete_course,
}) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const get_Courses = async () => {
      setLoading(true);
      let res = await getCourses();
      if (res) {
        setLoading(false);
      }
    };
    get_Courses();

    return () => {
      clearAllCourses();
    };
  }, [getCourses, clearAllCourses]);

  //   const [toggleDetailCourse, setToggleDetailCourse] = useState(false);

  //   const closeDetailCourseModal = (e) => {
  //     e.preventDefault();

  //     setToggleDetailCourse(false);
  //   };

  //   const [options, setOptions] = useState([]);

  //   const deleteCourse = (e, courseID, categoryID) => {
  //     e.preventDefault();

  //     // // // console.log('Course ID: ', courseID);
  //     // // // console.log('Category ID: ', categoryID);

  //     delete_course(categoryID, courseID);

  //     //   setToggleDetailCourse(true);
  //   };

  //   const generateOptions = (number) => {
  //     let optionss = [];
  //     for (let index = 0; index < number; index++) {
  //       //   options = options + `<option value=${index}>${index}</option>`;
  //       optionss.push(`<option value=${index}>${index}</option>`);
  //       //   return <option value={index}>{index}</option>;
  //     }
  //     // options = '(' + options + ')';
  //     setOptions([...optionss]);
  //     // return options;
  //   };

  //   let config = {
  //     key_column: '_id',
  //     page_size: 10,
  //     length_menu: [10, 20, 50],
  //     show_filter: true,
  //     show_pagination: true,
  //     pagination: 'advance',
  //     button: {
  //       excel: false,
  //       print: false,
  //     },
  //     language: {
  //       loading_text: 'Please be patient while data loads...',
  //     },
  //   };

  //   let columns = [
  //     {
  //       key: 'image',
  //       text: 'Image',
  //       className: 'image',
  //       align: 'left',
  //       //   sortable: true,
  //       cell: (record) => {
  //         return (
  //           <img
  //             src={IMAGE_URL_ENDPOINT + '/' + record.image}
  //             alt='Sorry for Inconvenience'
  //           />
  //         );
  //       },
  //     },
  //     {
  //       key: 'name',
  //       text: 'Name',
  //       className: 'name',
  //       align: 'left',
  //       sortable: true,
  //     },
  //     {
  //       key: 'action',
  //       text: 'Action',
  //       className: 'action',
  //       //   width: 100,
  //       //   align: 'left',
  //       //   sortable: false,
  //       cell: (record) => {
  //         return (
  //           <Fragment>
  //             {/* <Link
  //               to={'/detail/' + record._id}
  //               className='btn btn-danger'
  //               style={{ marginRight: '5px' }}
  //             >
  //               <Icofont icon='edit' size='2' />
  //             </Link> */}
  //             <Link to={'/detail/' + record._id} className='btn btn-gradient-01'>
  //               <Icofont icon='list' size='2' />
  //             </Link>
  //             <button
  //               className='btn btn-gradient-01'
  //               onClick={(e) => deleteCourse(e, record._id, record.category_id)}
  //               style={{ marginLeft: '5px' }}
  //             >
  //               <Icofont icon='ui-delete' size='2' />
  //             </button>
  //             {/* <button
  //               className='btn btn-danger'
  //               // onClick={() => this.deleteRecord(record)}
  //             >
  //               <Icofont icon='edit' size='2' />
  //             </button> */}
  //           </Fragment>
  //         );
  //       },
  //     },
  //   ];

  return (
    <Fragment>
      {/* {allCourses !== null ? ( */}
      <div className='row'>
        {!loading ? (
          allCourses.map((data, i) => (
            <div className='col-md-12  animated zoomIn' key={i}>
              <h1>{data.Category}</h1>
              <div className='col-md-12'>
                {data.data.map((data, i) => (
                  <div key={i}>
                    <div>
                      <h3>{data.title}</h3>
                    </div>
                    <div className='col-md-12'>
                      <DataTableWithSorting
                        data={data.data}
                        categoryID={data.category_id}
                        index={i}
                        key={i}
                      />
                      {/* <ReactDatatable
                      config={config}
                      records={data.data}
                      columns={columns}
                      loading={loadingCourses}
                      //   style={{ width: '100%' }}
                      // extraButtons={this.extraButtons}
                    /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className='w-100'>
            <div className='d-flex justify-content-center'>
              {/* <span className='icofont-spinner'></span> */}
              <Icofont
                icon='spinner'
                size='3'
                spin='true'
                style={{ color: 'var(--blue)' }}
              />
            </div>
          </div>
        )}
        {/* <ReactDatatable
          config={config}
          records={allCourses}
          columns={columns}
          loading={loadingCourses}
          //   style={{ width: '100%' }}
          // extraButtons={this.extraButtons}
        /> */}
        {/* <div className='table-responsive'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Course Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody style={{ height: '60vh' }}>
                {allCourses.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <img
                        src={IMAGE_URL_ENDPOINT + data.image}
                        alt='Sorry for Inconvenience'
                      />
                    </td>
                    <td>{data.name}</td>
                    <td className='td-actions'>
                      <button
                        // onClick={(e) => toggleAddVehicleForm(e)}
                        className='btn btn-gradient-01'
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        {/* {allCourses.map((data, i) => (
            <div className='col-md-4' key={i}>
              <div className='widget has-shadow vehicle-card'>
                <div className='vehicle-images' style={{ width: '100%' }}>

                  <img
                    src={ADMIN_API_ENDPOINT + data.image}
                    alt='vehicle-images'
                  />

                </div>
                <div className='vehicle-content'>
                  <h5>Name: {data.name}</h5>

                  <div className='text-right'>
                    <button
                      // onClick={(e) => toggleAddVehicleForm(e)}
                      className='btn btn-gradient-01'
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        {/* <div className='col-md-4'>
            <div className='widget has-shadow vehicle-card'>
              <div className='vehicle-images' style={{ width: '100%' }}>
                <Swiper
                  // spaceBetween={50}
                  slidesPerView={1}
                  // onSlideChange={() => // // // console.log('slide change')}
                  // onSwiper={(swiper) => // // // console.log(swiper)}
                  // navigation
                  // autoplay={true}
                  loop={true}
                  // pagination={{ clickable: true }}
                >
                  <SwiperSlide zoom={true}>
                    <img src={Image3} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image5} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image1} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image4} alt='vehicle-images' />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className='vehicle-content'>
                <h5>Name: Honda City</h5>
                <h5>Model: 2014</h5>
                <h5>Number: BBG-977</h5>
                <h5>Color: Black</h5>
                <div className='text-right'>
                  <button
                    // onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='widget has-shadow vehicle-card'>
              <div className='vehicle-images' style={{ width: '100%' }}>
                <Swiper
                  // spaceBetween={50}
                  slidesPerView={1}
                  // onSlideChange={() => // // // console.log('slide change')}
                  // onSwiper={(swiper) => // // // console.log(swiper)}
                  // navigation
                  // autoplay={true}
                  loop={true}
                  // pagination={{ clickable: true }}
                >
                  <SwiperSlide zoom={true}>
                    <img src={Image4} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image5} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image3} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image1} alt='vehicle-images' />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className='vehicle-content'>
                <h5>Name: Honda City</h5>
                <h5>Model: 2014</h5>
                <h5>Number: BBG-977</h5>
                <h5>Color: Black</h5>
                <div className='text-right'>
                  <button
                    // onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='widget has-shadow vehicle-card'>
              <div className='vehicle-images' style={{ width: '100%' }}>
                <Swiper
                  // spaceBetween={50}
                  slidesPerView={1}
                  // onSlideChange={() => // // // console.log('slide change')}
                  // onSwiper={(swiper) => // // // console.log(swiper)}
                  // navigation
                  // autoplay={true}
                  loop={true}
                  // pagination={{ clickable: true }}
                >
                  <SwiperSlide zoom={true}>
                    <img src={Image5} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image1} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image3} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image4} alt='vehicle-images' />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className='vehicle-content'>
                <h5>Name: Honda City</h5>
                <h5>Model: 2014</h5>
                <h5>Number: BBG-977</h5>
                <h5>Color: Black</h5>
                <div className='text-right'>
                  <button
                    // onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='widget has-shadow vehicle-card'>
              <div className='vehicle-images' style={{ width: '100%' }}>
                <Swiper
                  // spaceBetween={50}
                  slidesPerView={1}
                  // onSlideChange={() => // // // console.log('slide change')}
                  // onSwiper={(swiper) => // // // console.log(swiper)}
                  // navigation
                  // autoplay={true}
                  loop={true}
                  // pagination={{ clickable: true }}
                >
                  <SwiperSlide zoom={true}>
                    <img src={Image1} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image5} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image3} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image4} alt='vehicle-images' />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className='vehicle-content'>
                <h5>Name: Honda City</h5>
                <h5>Model: 2014</h5>
                <h5>Number: BBG-977</h5>
                <h5>Color: Black</h5>
                <div className='text-right'>
                  <button
                    // onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='widget has-shadow vehicle-card'>
              <div className='vehicle-images' style={{ width: '100%' }}>
                <Swiper
                  // spaceBetween={50}
                  slidesPerView={1}
                  // onSlideChange={() => // // // console.log('slide change')}
                  // onSwiper={(swiper) => // // // console.log(swiper)}
                  // navigation
                  // autoplay={true}
                  loop={true}
                  // pagination={{ clickable: true }}
                >
                  <SwiperSlide zoom={true}>
                    <img src={Image1} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image5} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image3} alt='vehicle-images' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Image4} alt='vehicle-images' />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className='vehicle-content'>
                <h5>Name: Honda City</h5>
                <h5>Model: 2014</h5>
                <h5>Number: BBG-977</h5>
                <h5>Color: Black</h5>
                <div className='text-right'>
                  <button
                    // onClick={(e) => toggleAddVehicleForm(e)}
                    className='btn btn-gradient-01'
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div> */}
      </div>
      {/* ) : null} */}

      {/* <DetailCourse
        closeModal={closeDetailCourseModal}
        toggleDetailModal={toggleDetailCourse}
      /> */}
    </Fragment>
  );
}

ViewCourse.propTypes = {
  toggling: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired,
  delete_course: PropTypes.func.isRequired,
  clearAllCourses: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  toggling: state.toggling,
  courses: state.courses,
});

export default connect(mapStateToProps, {
  getCourses,
  delete_course,
  clearAllCourses,
})(ViewCourse);

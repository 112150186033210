import { SET_NAVBAR_TOGGLE } from './types';

export const toggleNavbar = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_NAVBAR_TOGGLE,
    });
  } catch (error) {
    // // // console.log(error);
  }
};

import { combineReducers } from 'redux';
import toggling from './toggling';
import auth from './auth';
import courses from './courses';
import users from './users';

export default combineReducers({
  courses,
  auth,
  toggling,
  users,
});

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { delete_course, reorderingCourses } from '../../../actions/courses';
import { IMAGE_URL_ENDPOINT } from '../../../actions/types';
import Icofont from 'react-icofont';
import { Table } from 'antd';
import 'antd/dist/antd.css';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

function DataTableWithSorting({
  data,
  categoryID,
  delete_course,
  reorderingCourses,
  //   index,
}) {
  const [dataSource, setData] = useState(data);
  const [oldData, setOldData] = useState(data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // // // // console.log('New Data: ', data);
    setData(data);
  }, [data]);

  // const [data, setData] = useState(
  //   allCourses.length >= 1 ? allCourses[0].data[0].data : null
  // );

  //   // // // console.log(allCourses[0]);

  const deleteCourse = (e, courseID) => {
    e.preventDefault();

    // // // console.log('Course ID: ', courseID);
    // // // console.log('Category ID: ', categoryID);

    delete_course(categoryID, courseID);

    //   setToggleDetailCourse(true);
  };

  const newColumns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      className: 'drag-visible',
      render: (text, record, index) => (
        <img src={record.image} alt='Sorry for Inconvenience' />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'Name',
      className: 'drag-visible',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => (
        <Fragment>
          {/* <Link
            to={'/detail/' + record._id}
            className='btn btn-danger'
            style={{ marginRight: '5px' }}
          >
            <Icofont icon='edit' size='2' />
          </Link> */}
          <Link to={'/detail/' + record._id} className='btn btn-gradient-01'>
            <Icofont icon='list' size='2' />
          </Link>
          <button
            className='btn btn-gradient-01'
            onClick={(e) => deleteCourse(e, record._id, record.category_id)}
            style={{ marginLeft: '5px' }}
          >
            <Icofont icon='ui-delete' size='2' />
          </button>
          {/* <button
            className='btn btn-danger'
            // onClick={() => this.deleteRecord(record)}
          >
            <Icofont icon='edit' size='2' />
          </button> */}
        </Fragment>
      ),
    },
  ];

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    // const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // // // console.log('Sorted items: ', newData);
      setData(newData);
      setOldData(data);

      setLoading(true);

      let res = await reorderingCourses(newData);

      if (!res) {
        setLoading(false);
        setData(oldData);
      } else {
        setLoading(false);
        setData(newData);
      }

      //   this.setState({ dataSource: newData });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    // if (dataSource !== null) {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
    // }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass='row-dragging'
      onSortEnd={(e) => onSortEnd(e)}
      {...props}
    />
  );

  return (
    <div>
      {/* {loading ? (
        <Icofont icon='spinner' size='3' spin='true' />
      ) : ( */}
      <Table
        pagination={{
          defaultPageSize: '4',
          pageSizeOptions: ['4', '10', '20', '30'],
          showSizeChanger: true,
          //   locale: { items_per_page: '' },
        }}
        // scroll={{ y: 500 }}
        // style={{ color: 'black' }}
        dataSource={dataSource}
        columns={newColumns}
        loading={loading}
        rowKey='index'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: (e) => DraggableBodyRow(e),
          },
        }}
      />
      {/* )} */}
    </div>
  );
}

DataTableWithSorting.propTypes = {
  delete_course: PropTypes.func.isRequired,
  reorderingCourses: PropTypes.func.isRequired,
};

export default connect(null, { delete_course, reorderingCourses })(
  DataTableWithSorting
);

import {
  GET_COURSES,
  CLEAR_COURSES_MEMORY,
  GET_CATEGORIES,
  GET_LESSONS,
  GET_SINGLE_LESSON_DETAIL,
  GET_SINGLE_LESSON_DETAILS_LOADING,
  UPDATE_VIDEOS,
  UPDATE_CONTENT,
  LOADING_VIDEOS,
  UPDATE_VIDEOS_DETAILS,
  LOAD_COURSES,
  EDIT_COURSE_NAME,
  EDIT_THUMBNAIL,
  EDIT_BANNER,
  CREATE_QUIZ,
  DELETE_QUIZ,
  GET_SINGLE_LESSON_VIDEOS,
  DELETE_CONTENT,
  CREATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  DELETE_VIDEO,
  LOADING_LESSON,
  DELETE_LESSON,
  EDIT_LESSON,
  DELETE_COURSE,
  GET_SINGLE_QUIZ,
  UPDATE_QUIZ_LOADING,
  UPDATE_QUIZ,
  UPDATE_ASSIGNMENT_LOADING,
  UPDATE_ASSIGNMENT,
  GET_SINGLE_ASSIGNMENT,
  SET_PROGRESS,
  EDIT_SWITCH_STATUS,
  EDIT_DESCRIPTION,
} from '../actions/types';

const initialState = {
  allCourses: [],
  LessonListData: null,
  singleModalLessonVideos: null,
  singleLessonVideos: null,
  singleLessonQuizes: null,
  singleLessonAssignments: null,
  singleLessonContent: null,
  singleLessonVideosLoading: true,

  singleLessonQuizesLoading: true,

  singleLessonAssignmentsLoading: true,

  singleLessonContentLoading: true,
  allCategories: null,
  loadingCourses: true,
  loadingCategories: true,
  loadingLessons: false,
  singleRecordQuiz: null,
  singleRecordAssignment: null,
  progress: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case LOAD_COURSES:
      return {
        ...state,
        loadingCourses: true,
      };
    case GET_COURSES:
      return {
        ...state,
        allCourses: payload,
        loadingCourses: false,
        singleLessonVideos: null,
        singleLessonQuizes: null,
        singleLessonAssignments: null,
        singleLessonContent: null,
        LessonListData: null,
        singleLessonVideosLoading: false,
        singleLessonQuizesLoading: false,

        singleLessonAssignmentsLoading: false,

        singleLessonContentLoading: false,
      };
    case CLEAR_COURSES_MEMORY: {
      return {
        ...state,
        allCourses: [],
        loadingCourses: false,
        singleLessonVideos: null,
        singleLessonQuizes: null,
        singleLessonAssignments: null,
        singleLessonContent: null,
        LessonListData: null,
        singleLessonVideosLoading: false,
        singleLessonQuizesLoading: false,

        singleLessonAssignmentsLoading: false,

        singleLessonContentLoading: false,
      };
    }
    case GET_CATEGORIES:
      return {
        ...state,
        allCategories: payload,
      };
    case GET_LESSONS:
      return {
        ...state,
        LessonListData: payload,
        singleLessonVideosLoading: false,
        singleLessonQuizesLoading: false,

        singleLessonAssignmentsLoading: false,

        singleLessonContentLoading: false,
      };
    case GET_SINGLE_LESSON_DETAILS_LOADING:
      return {
        ...state,
        singleLessonVideosLoading: true,

        singleLessonQuizesLoading: true,

        singleLessonAssignmentsLoading: true,

        singleLessonContentLoading: true,
      };
    case GET_SINGLE_LESSON_DETAIL:
      return {
        ...state,
        singleLessonVideos: payload.videos,
        singleLessonQuizes: payload.quizes,
        singleLessonAssignments: payload.assignments,
        singleLessonContent: payload.content,
        singleLessonVideosLoading: false,
        singleLessonQuizesLoading: false,

        singleLessonAssignmentsLoading: false,

        singleLessonContentLoading: false,
      };
    case GET_SINGLE_LESSON_VIDEOS:
      return {
        ...state,
        singleModalLessonVideos: payload.videos,
      };

    case UPDATE_VIDEOS_DETAILS:
      state.singleLessonVideos.forEach(function (obj) {
        if (obj.id === payload.id) {
          //change the value here
          obj.video_title = payload.data.title;
          obj.video_description = payload.data.video_description;
          return true; //breaks out of he loop
        }
      });
      return {
        ...state,
      };

    case UPDATE_VIDEOS:
      return {
        ...state,
        singleLessonVideos: payload.videos,
        singleLessonVideosLoading: false,
      };
    case LOADING_VIDEOS:
      return {
        ...state,
        singleLessonVideosLoading: payload,
      };

    case LOADING_LESSON:
      return {
        ...state,
        loadingLessons: true,
      };

    case UPDATE_CONTENT:
      return {
        ...state,
        singleLessonContent: payload.content,
        // singleLessonVideosLoading: false,
      };

    case CREATE_ASSIGNMENT:
      return {
        ...state,
        singleLessonAssignments: payload.assignment,
        // singleLessonVideosLoading: false,
      };

    case CREATE_QUIZ:
      return {
        ...state,
        singleLessonQuizes: payload.quizes,
      };

    case GET_SINGLE_QUIZ:
      return {
        ...state,
        singleRecordQuiz: payload,
      };

    case GET_SINGLE_ASSIGNMENT:
      return {
        ...state,
        singleRecordAssignment: payload,
      };

    case DELETE_QUIZ:
      return {
        ...state,
        singleLessonQuizes: state.singleLessonQuizes.filter(
          (item) => item._id !== payload
        ),
      };

    case UPDATE_QUIZ:
      state.singleLessonQuizes.forEach(function (obj) {
        if (obj._id === payload.ID) {
          //change the value here
          obj.title = payload.title;
          obj.link = payload.link;
          return true; //breaks out of he loop
        }
      });
      return {
        ...state,
        singleLessonQuizesLoading: false,
      };

    case UPDATE_ASSIGNMENT_LOADING:
      return {
        ...state,
        singleLessonAssignmentsLoading: payload,
      };

    case UPDATE_ASSIGNMENT:
      state.singleLessonAssignments.forEach(function (obj) {
        if (obj._id === payload.ID) {
          //change the value here
          obj.title = payload.title;
          obj.pdffile = payload.pdffile;
          return true; //breaks out of he loop
        }
      });
      return {
        ...state,
        singleLessonAssignmentsLoading: false,
      };

    case UPDATE_QUIZ_LOADING:
      return {
        ...state,
        singleLessonQuizesLoading: payload,
      };

    case DELETE_CONTENT:
      return {
        ...state,
        singleLessonContent: state.singleLessonContent.filter(
          (item) => item._id !== payload
        ),
      };

    case DELETE_ASSIGNMENT:
      return {
        ...state,
        singleLessonAssignments: state.singleLessonAssignments.filter(
          (item) => item._id !== payload
        ),
      };

    case DELETE_VIDEO:
      return {
        ...state,
        singleLessonVideos: state.singleLessonVideos.filter(
          (item) => item.id !== payload
        ),
        singleLessonVideosLoading: false,
      };

    case DELETE_LESSON:
      return {
        ...state,
        LessonListData: {
          ...state.LessonListData,
          lessons: state.LessonListData.lessons.filter(
            (item) => item._id !== payload
          ),
        },
        loadingLessons: false,
        singleLessonVideos: null,
        singleLessonQuizes: null,
        singleLessonAssignments: null,
        singleLessonContent: null,
      };

    case DELETE_COURSE:
      state.allCourses.forEach((data, i) => {
        // // // // console.log('First For each data: ', data);
        data.data.forEach((data, i) => {
          //   // // // console.log('Second For Each data: ', data);
          if (data.category_id === payload.category_id) {
            // // // // console.log('data Matched: ', data);
            // let mydata = [];
            data.data = data.data.filter(
              (item) => item._id !== payload.course_id
            );
            // // // // console.log('hahaha data: ', data.data);
            //   data.data = [...mydata];
            return true;
          }
        });
      });
      return {
        ...state,
        // allCourses: state.allCourses.filter((item) => item._id !== payload),
        loadingCourses: false,
      };

    case EDIT_COURSE_NAME:
      return {
        ...state,
        LessonListData: {
          ...state.LessonListData,
          courseName: payload,
        },
      };
    case EDIT_DESCRIPTION:
      return {
        ...state,
        LessonListData: {
          ...state.LessonListData,
          description: payload,
        },
      };
    case EDIT_LESSON:
      // // // console.log(payload);
      state.LessonListData.lessons.forEach(function (obj) {
        if (obj._id === payload.ID) {
          //change the value here
          obj.name = payload.name;
          return true; //breaks out of he loop
        }
      });
      return {
        ...state,
        // LessonListData: {
        //   ...state.LessonListData,
        //   lessons: ,
        // },
      };
    case EDIT_SWITCH_STATUS:
      // // // console.log(payload);
      state.LessonListData.lessons.forEach(function (obj) {
        if (obj._id === payload.ID) {
          //change the value here
          obj.status_subscription = payload.status_subscription;
          return true; //breaks out of he loop
        }
      });
      return {
        ...state,
        // LessonListData: {
        //   ...state.LessonListData,
        //   lessons: ,
        // },
      };
    case EDIT_THUMBNAIL:
      return {
        ...state,
        LessonListData: {
          ...state.LessonListData,
          image: payload,
        },
      };
    case EDIT_BANNER:
      return {
        ...state,
        LessonListData: {
          ...state.LessonListData,
          banner_image: payload,
        },
      };
    default:
      return state;
  }
}

import {
  ADMIN_API_ENDPOINT,
  GET_LESSONS,
  GET_SINGLE_LESSON_DETAILS_LOADING,
  GET_SINGLE_LESSON_DETAIL,
  AUTH_ERROR,
  GET_SINGLE_LESSON_VIDEOS,
  LOADING_LESSON,
  DELETE_LESSON,
  EDIT_LESSON,
  SET_PROGRESS,
  EDIT_SWITCH_STATUS,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// CREATE LESSON

export const createLesson = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  //   // // // console.log('course Detail data: ', courseDetail);

  try {
    const res = await axios.post(
      `${ADMIN_API_ENDPOINT}/lessons/`,
      data,
      config
    );

    // // // console.log('Create Lesson Response: ', res.data);

    dispatch({
      type: GET_LESSONS,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Lesson Created',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('Create Lesson Data error: ', error.response.status);

      if (error.response.status === 401) {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Create Lesson Data error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }

    // // // // console.log('Create Lesson Data error: ', error);

    // new Noty({
    //   type: 'error',
    //   layout: 'bottomCenter',
    //   text: 'Lesson Not Created',
    //   timeout: 3000,
    // }).show();
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// GET ALL LESSONS FOR SINGLE COURSE

export const getLessonsOfSingleCourse = (courseID) => async (dispatch) => {
  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/courses/${courseID}/`);

    dispatch({
      type: GET_LESSONS,
      payload: res.data,
    });

    //   new Noty({
    //     type: 'success',
    //     layout: 'bottomCenter',
    //     text: 'Course Created',
    //     timeout: 3000,
    //   }).show();

    // // // console.log('Course Lesson List Response: ', res.data);
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Course Lesson List Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Course Lesson List Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: 'Server is not responding',
        timeout: 3000,
      }).show();
    }
  }
};

// Get Complete Details For Single Lesson

export const getDetailForSingleLesson =
  (LessonID, type) => async (dispatch) => {
    const config = {
      onDownloadProgress: (ev) => {
        const progress = (ev.loaded / ev.total) * 100;
        //   // // // console.log(ev.loaded, ev.total, progress);
        dispatch({
          type: SET_PROGRESS,
          payload: Math.round(progress),
        });
      },
    };
    try {
      dispatch({
        type: SET_PROGRESS,
        payload: 0,
      });
      if (type !== 'modal') {
        dispatch({
          type: GET_SINGLE_LESSON_DETAILS_LOADING,
        });
      } else {
        dispatch({
          type: GET_SINGLE_LESSON_VIDEOS,
          payload: {
            videos: null,
          },
        });
      }

      // // // console.log('Lesson ID Actions: ', LessonID);

      const res = await axios.get(
        //   `${ADMIN_API_ENDPOINT}/lessons/${LessonID}/?timestamp=${new Date().getTime()}`
        `${ADMIN_API_ENDPOINT}/lessons/${LessonID}/`,
        config
      );

      // const res = await fetch(`${ADMIN_API_ENDPOINT}/lessons/${LessonID}/`, {
      //   method: 'get',
      //   headers: new Headers({
      //     Authorization: 'Bearer ' + localStorage.getItem('token'),
      //     'Content-Type': 'application/json',
      //   }),
      // });

      // fetch(`${ADMIN_API_ENDPOINT}/lessons/${LessonID}/`)

      if (type !== 'modal') {
        dispatch({
          type: GET_SINGLE_LESSON_DETAIL,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SINGLE_LESSON_VIDEOS,
          payload: res.data,
        });
      }

      //   new Noty({
      //     type: 'success',
      //     layout: 'bottomCenter',
      //     text: 'Course Created',
      //     timeout: 3000,
      //   }).show();

      // // // console.log('Details of lesson Response: ', res.data);
    } catch (error) {
      // // // console.log('Details of lesson Error: ', error);
    }
  };

// Edit Lesson Name

export const editLessonName = (data, name, ID, source) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.put(
      `${ADMIN_API_ENDPOINT}/lessons/${ID}/`,
      data,
      config
    );

    if (source === 'lessonName') {
      let changeData = {
        ID: ID,
        name: name,
      };

      // // // console.log('Edit Lesson Name Response: ', res.data);

      dispatch({
        type: EDIT_LESSON,
        payload: changeData,
      });

      new Noty({
        type: 'success',
        layout: 'bottomCenter',
        text: 'Lesson Name Edited',
        timeout: 3000,
      }).show();

      return true;
    } else {
      let changeData = {
        ID: ID,
        status_subscription: name === true ? '1' : '0',
      };

      // // // console.log('Edit Switch Free, Non Free Response: ', res.data);

      dispatch({
        type: EDIT_SWITCH_STATUS,
        payload: changeData,
      });

      new Noty({
        type: 'success',
        layout: 'bottomCenter',
        text: name === true ? 'Lesson Paid Now' : 'Lesson is unpaid now',
        timeout: 3000,
      }).show();

      return true;
    }
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Course Lesson Edit Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Course Lesson Edit Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// Delete Lesson

export const delete_lesson = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_LESSON,
    });

    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/lessons/${data.get(
        'lesson_id'
      )}/?course_id=${data.get('course_id')}`
    );

    // // // console.log('delete lesson Response: ', res.data);

    await dispatch({
      type: DELETE_LESSON,
      payload: id,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Lesson Deleted',
      timeout: 3000,
    }).show();

    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete Lesson Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Network Error',
          timeout: 3000,
        }).show();
      }
    } else {
      //   // // // console.log('Delete Lesson Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// REORDERING LESSONS

export const reorderingLessons = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.patch(
      `${ADMIN_API_ENDPOINT}/lessons/reordering/`,
      data,
      config
    );

    // // // console.log('Reordering Lessons Response: ', res.data);

    //   dispatch({
    //     type: CREATE_QUIZ,
    //     payload: res.data,
    //   });

    dispatch({
      type: GET_LESSONS,
      payload: res.data,
    });

    // new Noty({
    //   type: 'success',
    //   layout: 'bottomCenter',
    //   text: 'Notification Send',
    //   timeout: 3000,
    // }).show();

    //   dispatch({
    //     type: GET_COURSES,
    //     payload: res.data,
    //   });

    // if (res.data.description )
    return true;
  } catch (error) {
    if (error.message !== 'Network Error') {
      // // // console.log('Reordering Courses Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Reordering Courses Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }

    return false;
  }
};

import {
  ADMIN_API_ENDPOINT,
  AUTH_ERROR,
  CREATE_QUIZ,
  DELETE_QUIZ,
  GET_SINGLE_QUIZ,
  UPDATE_QUIZ,
  UPDATE_QUIZ_LOADING,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// Create Quiz

export const createNewQuiz = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post(`${ADMIN_API_ENDPOINT}/quizes/`, data, config);

    // // // console.log('Create Quiz Response: ', res.data);

    dispatch({
      type: CREATE_QUIZ,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Quiz Created',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Create Quiz Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Create Quiz Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// Get Single Record Quiz

export const getSingleRecordQuiz = (id) => async (dispatch) => {
  // const config = {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  // };

  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/quizes/${id}/`);

    // // // console.log('GET SINGLE Quiz Response: ', res.data);

    await dispatch({
      type: GET_SINGLE_QUIZ,
      payload: res.data,
    });

    //   new Noty({
    //     type: 'success',
    //     layout: 'bottomCenter',
    //     text: 'Quiz Created',
    //     timeout: 3000,
    //   }).show();

    // if (res.data.description )
    return {
      data: res.data,
      status: true,
    };
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('GET SINGLE Quiz Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('GET SINGLE Quiz Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// Update Quiz

export const updateQuizContent = (data, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    dispatch({
      type: UPDATE_QUIZ_LOADING,
      payload: true,
    });

    const res = await axios.put(
      `${ADMIN_API_ENDPOINT}/quizes/${id}/`,
      data,
      config
    );

    // // // console.log('Update Quiz Response: ', res.data);

    const updatedData = {
      ID: id,
      title: data.get('title'),
      link: data.get('link'),
    };

    dispatch({
      type: UPDATE_QUIZ,
      payload: updatedData,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Quiz Updated',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Update Quiz Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
      dispatch({
        type: UPDATE_QUIZ_LOADING,
        payload: false,
      });
    } else {
      // // // console.log('Update Quiz Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.message,
        timeout: 3000,
      }).show();
      dispatch({
        type: UPDATE_QUIZ_LOADING,
        payload: false,
      });
    }
  }
};

// Delete Quiz

export const delete_quiz = (data, id) => async (dispatch) => {
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   };

  // // // console.log(data.get('lesson_id'));

  const sendData = {
    data: {
      lesson_id: data.get('lesson_id'),
    },
  };

  // // // console.log(sendData);

  try {
    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/quizes/${id}/?lesson_id=${data.get(
        'lesson_id'
      )}&course_id=${data.get('course_id')}`
      //   { data: { lesson_id: data.get('lesson_id') } }
      //   config
    );

    // // // console.log('delete Quiz Response: ', res.data);

    dispatch({
      type: DELETE_QUIZ,
      payload: id,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Quiz Deleted',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete Quiz Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Delete Quiz Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

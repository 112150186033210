import React, { useEffect } from 'react';
import LoginRegister from './components/auth/LoginRegister';
import Dashboard from './components/dashboard/Dashboard';
// import ViewCourse from './components/dashboard/CourseManagement/ViewCourse';
import DetailCourse from './components/dashboard/CourseManagement/DetailCourse';
import AddCar from './components/dashboard/AddCar';
import AllUsers from './components/dashboard/UserManagement/AllUsers';
import CreateBooking from './components/dashboard/CreateBooking';
// import TestingReordering from './components/dashboard/CourseManagement/TestingReordering';
// import TestingLessonReordering from './components/dashboard/CourseManagement/TestingLessonReordering';
import Navbar from './components/layouts/Navbar';
import setAuthToken from './utils/setAuthToken';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import { loadUser } from './actions/auth';
import ErrorPage from './components/layouts/ErrorPage';
// import logo from "./logo.svg";
// import Logo from "./assets/img/logo.png";
// import './App.css';

// Redux

import { Provider } from 'react-redux';
import store from './store';
// import DetailCourse from './components/dashboard/CourseManagement/DetailCourse';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    // let history = useHistory();
    store.dispatch(loadUser());
  }, []);

  const LoginContainer = () => (
    <div>
      <Route exact path='/' component={LoginRegister}></Route>
    </div>
  );

  const DefaultContainer = () => (
    <div>
      <Navbar />
      <PrivateRoute
        exact
        path='/dashboard'
        component={Dashboard}
      ></PrivateRoute>
      {/* <PrivateRoute
        // exact
        path='/detailCourse/:id'
        component={DetailCourse}
      ></PrivateRoute> */}
      <PrivateRoute exact path='/detail/:id' component={DetailCourse} />
      <PrivateRoute
        exact
        path='/dashboard/course-management'
        component={AddCar}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path='/dashboard/user-management'
        component={AllUsers}
      ></PrivateRoute>
      {/* <PrivateRoute
        exact
        path='/dashboard/testing-reordering'
        component={TestingReordering}
      ></PrivateRoute> */}
      <PrivateRoute
        exact
        path='/dashboard/create-booking'
        component={CreateBooking}
      ></PrivateRoute>
    </div>
  );

  return (
    <Provider store={store}>
      <Router>
        <div>
          <Switch>
            <Route exact path='/' component={LoginContainer}></Route>
            <Route
              exact
              path='/(dashboard)'
              component={DefaultContainer}
            ></Route>
            <Route
              //   exact
              path='/(detail)/:id'
              component={DefaultContainer}
            ></Route>
            <Route
              exact
              path='/(dashboard)/(course-management)'
              component={DefaultContainer}
            ></Route>
            <Route
              exact
              path='/(dashboard)/(user-management)'
              component={DefaultContainer}
            ></Route>
            {/* <Route
              exact
              path='/(dashboard)/(testing-reordering)'
              component={DefaultContainer}
            ></Route> */}
            <Route
              exact
              path='/(dashboard)/(create-booking)'
              component={DefaultContainer}
            ></Route>
            <Route component={ErrorPage}></Route>
          </Switch>
        </div>
      </Router>
    </Provider>

    // <div classNameName="App">
    //   <header classNameName="App-header">
    //     <img src={logo} classNameName="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       classNameName="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;

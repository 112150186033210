import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editCourseName, sendNotification } from '../../../actions/courses';
import {
  createAssignmentForSingleLesson,
  getSingleRecordAssignment,
  delete_assignment,
  updateAssignmentContent,
} from '../../../actions/assignments';

import {
  createContentForSingleLesson,
  delete_content,
  reorderingContents,
} from '../../../actions/content';
import {
  createNewQuiz,
  updateQuizContent,
  delete_quiz,
  getSingleRecordQuiz,
} from '../../../actions/quizes';
import {
  createVideoForSingleLesson,
  editVideoForSingleLesson,
  delete_video,
  reorderingVideos,
} from '../../../actions/videos';
import {
  getLessonsOfSingleCourse,
  getDetailForSingleLesson,
  createLesson,
  editLessonName,
  delete_lesson,
  reorderingLessons,
} from '../../../actions/lessons';
import { IMAGE_URL_ENDPOINT } from '../../../actions/types';
// Import Swiper styles.
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Zoom,
} from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import Icofont from 'react-icofont';
import '../../../assets/css/DetailCourse.css';

import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Card,
} from 'react-bootstrap';
// import ReactQuill from 'react-quill';
// import EditorToolbar, { modules, formats } from './EditorToolbar';
// import 'react-quill/dist/quill.snow.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom]);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function DetailCourse({
  toggling: { toggleNavbarBurger },
  match,
  getLessonsOfSingleCourse,
  getDetailForSingleLesson,
  createLesson,
  createVideoForSingleLesson,
  editVideoForSingleLesson,
  createContentForSingleLesson,
  editCourseName,
  createNewQuiz,
  getSingleRecordQuiz,
  getSingleRecordAssignment,
  createAssignmentForSingleLesson,
  delete_quiz,
  delete_content,
  delete_assignment,
  delete_video,
  delete_lesson,
  sendNotification,
  editLessonName,
  updateQuizContent,
  updateAssignmentContent,
  reorderingLessons,
  reorderingVideos,
  reorderingContents,
  courses,
}) {
  useEffect(() => {
    // const getData = () => {
    getLessonsOfSingleCourse(match.params.id);
    // };
    // getData();
  }, [getLessonsOfSingleCourse, match.params.id]);

  useEffect(() => {
    if (!courses.loadingLessons && courses.LessonListData !== null) {
      setLessons(courses.LessonListData.lessons);
    }
    if (
      !courses.singleLessonVideosLoading &&
      courses.singleLessonVideos !== null
    ) {
      setVideos(courses.singleLessonVideos);
    }
    if (
      !courses.singleLessonContentLoading &&
      courses.singleLessonContent !== null
    ) {
      setContents(courses.singleLessonContent);
    }
  }, [
    courses,
    courses.loadingLessons,
    courses.LessonListData,
    courses.singleLessonVideos,
    courses.singleLessonContent,
  ]);

  const [Lessons, setLessons] = useState(null);

  const [Videos, setVideos] = useState(null);

  const [Contents, setContents] = useState(null);

  const [loadingReorderingLessons, setLoadingReorderingLessons] =
    useState(false);

  const [loadingReorderingVideos, setLoadingReorderingVideos] = useState(false);

  const [loadingReorderingContents, setLoadingReorderingContents] =
    useState(false);

  //   const [progress, setProgress] = useState(0);

  const [modalShow, setModalShow] = useState(false);

  const [AssignmentModalShow, setAssignmentModalShow] = useState(false);

  const [ContentModalShow, setContentModalShow] = useState(false);

  const [editCourseDescriptionModalShow, setEditCourseDescriptionModalShow] =
    useState(false);

  const [quizData, setQuizData] = useState({
    name: '',
    // link: '',
  });

  const [quizDataLink, setQuizDataLink] = useState('');

  const [quizID, setQuizId] = useState('');

  //   const [assignmentData, setAssignmentData] = useState({
  //     name: '',
  //     assignmentFile: '',
  //   });

  const [assignmentName, setAssignmentName] = useState('');

  const [assignmentDataLink, setAssignmentDataLink] = useState('');

  const [assignmentID, setAssignmentId] = useState('');

  const [contentData, setContentData] = useState({
    name: '',
    contentFile: '',
  });

  const [videoData, setVideoData] = useState({
    title: '',
    videoDescription: '',
    videoFile: null,
  });

  const [editData, setEditData] = useState({
    course_name: '',
    description: '',
    thumbnail: null,
    bannerImage: '',
  });

  const [toggleAddLessonInput, setToggleAddLessonInput] = useState(false);

  const [toggleEditLessonInput, setToggleEditLessonInput] = useState(false);

  const [toggleEditVideoTitleInput, setToggleEditVideoTitleInput] =
    useState(false);

  const [editLesson, setEditLesson] = useState('');

  const [editVideoData, setEditVideoData] = useState({
    editTitle: '',
    editDescription: '',
  });

  const [checkLessonID, setCheckLessonID] = useState('');

  const [checkVideoID, setCheckVideoID] = useState('');

  const [toggleLessonId, setToggleLessonId] = useState(null);

  const [toggleAddContentButton, setToggleAddContentButton] = useState(false);

  const [toggleAddQuizButton, setToggleAddQuizButton] = useState(false);

  const [toggleAddAssignmentButton, setToggleAddAssignmentButton] =
    useState(false);

  const [lessonLoader, setLessonLoader] = useState(false);

  const [lessonName, setLessonName] = useState('');

  const [toggleLessonName, setToggleLessonName] = useState('');

  const [toggleEditName, setToggleEditName] = useState(false);

  const [toggleEditDescription, setToggleEditDescription] = useState(false);

  const [toggleThumbnailConfirm, setToggleTumbnailConfirm] = useState(false);

  const [AddExistingVideoUI, setAddExistingVideoUI] = useState(false);

  const [AddNewVideoUI, setAddNewVideoUI] = useState(false);

  const [modalLessonId, setModalLessonId] = useState('');

  const [videoId, setVideoId] = useState('');

  const [singleQuizContent, setSingleQuizContent] = useState(``);

  const [singleAssignmentContent, setSingleAssignmentContent] = useState(``);

  const [toggleUpdateQuizButton, setToggleUpdateQuizButton] = useState(false);

  const [toggleUpdateAssignmentButton, setToggleUpdateAssignmentButton] =
    useState(false);

  const [switchStatus, setSwitchStatus] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  //   var moduless = {
  //     toolbar: [['task-list']],
  //     'task-list': true,
  //   };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Lessons,
      result.source.index,
      result.destination.index
    );

    // this.setState({
    //   items,
    // });

    setLoadingReorderingLessons(true);

    // await setOldLessons([...Lessons]);

    setLessons([...items]);

    let sendData = {
      course_id: match.params.id,
      lessons: [...items],
    };

    let res = await reorderingLessons(sendData);

    if (res) {
      setLoadingReorderingLessons(false);
    } else {
      setLoadingReorderingLessons(false);
      setLessons(courses.LessonListData.lessons);
    }

    // // // console.log(items, sendData);
  };

  const onDragVideoEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Videos,
      result.source.index,
      result.destination.index
    );

    // this.setState({
    //   items,
    // });

    setLoadingReorderingVideos(true);

    // await setOldLessons([...Lessons]);

    setVideos([...items]);

    // let sendData = {
    //   course_id: match.params.id,
    //   lessons: [...items],
    // };

    let res = await reorderingVideos(
      [...items],
      toggleLessonId,
      match.params.id
    );

    if (res) {
      setLoadingReorderingVideos(false);
    } else {
      setLoadingReorderingVideos(false);
      setVideos(courses.singleLessonVideos);
    }

    // // // console.log(items);
  };

  const onDragContentEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Contents,
      result.source.index,
      result.destination.index
    );

    console.log('Content reordered Data: ', items);

    // this.setState({
    //   items,
    // });

    setLoadingReorderingContents(true);

    // await setOldLessons([...Lessons]);

    setContents([...items]);

    let sendData = {
      course_id: match.params.id,
      lessons: [...items],
    };

    let res = await reorderingContents(
      [...items],
      toggleLessonId,
      match.params.id
    );

    if (res) {
      setLoadingReorderingContents(false);
    } else {
      setLoadingReorderingContents(false);
      setContents(courses.singleLessonContent);
    }

    // // // console.log(items);
  };

  const onChangeEditorField = (e) => {
    // e.preventDefault();

    setQuizDataLink(e);
  };

  const onChangeAssignmentEditorField = (e) => {
    // e.preventDefault();

    setAssignmentDataLink(e);
  };

  //   const testSubmitReactQuill = (e) => {
  //     e.preventDefault();

  //     // // // console.log(quizData);
  //   };

  const getLessonDetail = (e, id, name, status) => {
    e.preventDefault();

    // // // console.log('Lesson ID: ', id);

    setToggleLessonId(id);

    setToggleLessonName(name);

    getDetailForSingleLesson(id);

    if (status === '1') {
      setSwitchStatus(true);
    } else {
      setSwitchStatus(false);
    }
  };

  const addToggleLesson = (e) => {
    e.preventDefault();

    setToggleAddLessonInput(true);
  };

  const addEditTitle = async (e) => {
    e.preventDefault();

    // setLessonLoader(true);

    // // // console.log(editVideoData);

    e.preventDefault();

    let data = new FormData();

    data.append('course_id', match.params.id);
    data.append('lesson_id', toggleLessonId);
    data.append('title', editVideoData.editTitle); //For cache
    data.append('video_description', editVideoData.editDescription);

    setVideoId('');
    setModalLessonId('');
    setAddExistingVideoUI(false);

    const res = await editVideoForSingleLesson(data, checkVideoID);

    if (res == true) {
      setEditVideoData({
        editTitle: '',
        editDescription: '',
      });
      setToggleEditVideoTitleInput(false);
    }

    // var EditLessonData = new FormData();

    // EditLessonData.append('name', editLesson);
    // // EditLessonData.append('', checkLessonID);
    // EditLessonData.append('course_id', match.params.id);

    // let response = await editLessonName(
    //   EditLessonData,
    //   editLesson,
    //   checkLessonID,
    //   'lessonName'
    // );

    // if (response) {
    //   setToggleEditLessonInput(false);
    // } else {
    //   setToggleEditLessonInput(false);
    // }

    // // // // console.log('formdata: ', newLessonData);
    // for (var value of newLessonData.values()) {
    //   // // // console.log(value);
    // }
  };

  const addEditLesson = async (e) => {
    e.preventDefault();

    // setLessonLoader(true);

    var EditLessonData = new FormData();

    EditLessonData.append('name', editLesson);
    // EditLessonData.append('', checkLessonID);
    EditLessonData.append('course_id', match.params.id);

    let response = await editLessonName(
      EditLessonData,
      editLesson,
      checkLessonID,
      'lessonName'
    );

    if (response) {
      setToggleEditLessonInput(false);
    } else {
      setToggleEditLessonInput(false);
    }

    // // // // console.log('formdata: ', newLessonData);
    // for (var value of newLessonData.values()) {
    //   // // // console.log(value);
    // }
  };

  const addNewLesson = async (e) => {
    e.preventDefault();

    setToggleAddLessonInput(false);

    setLessonLoader(true);

    var newLessonData = new FormData();

    newLessonData.append('name', lessonName);
    newLessonData.append('course_id', match.params.id);

    let response = await createLesson(newLessonData);

    if (response) {
      setLessonLoader(false);
    } else {
      setLessonLoader(false);
    }

    // // // // console.log('formdata: ', newLessonData);
    // for (var value of newLessonData.values()) {
    //   // // // console.log(value);
    // }
  };

  const closeNewLesson = (e) => {
    e.preventDefault();
    setToggleAddLessonInput(false);
  };

  const closeEditLesson = (e) => {
    e.preventDefault();
    setToggleEditLessonInput(false);
  };

  const closeEditTitle = (e) => {
    e.preventDefault();
    setToggleEditVideoTitleInput(false);
  };

  //   const onChangeFile = async (e) => {
  //     e.preventDefault();

  //     let myFile = e.target.files[0];

  //     // // // console.log('file Change: ', myFile);
  //     // // // console.log('File name: ', myFile.name);

  //     if (e.target.name === 'videofile') {
  //       let createVideo = new FormData();

  //       createVideo.append('video_title', myFile.name);
  //       createVideo.append('video_file', myFile);
  //       createVideo.append('lesson_id', toggleLessonId);
  //       createVideo.append('course_id', match.params.id); //For cache

  //       let video = await createVideoForSingleLesson(createVideo);

  //       if (video) {
  //         let notificationData = {
  //           messageBody:
  //             myFile.name +
  //             ' added in ' +
  //             toggleLessonName +
  //             ' of ' +
  //             courses.LessonListData.courseName,
  //           title: 'GetLoop',
  //           image: IMAGE_URL_ENDPOINT + courses.LessonListData.image,
  //         };

  //         sendNotification(notificationData);
  //       }
  //     } else if (e.target.name === 'assignmentfile') {
  //       setToggleAddAssignmentButton(true);

  //       let createAssignment = new FormData();

  //       createAssignment.append('title', myFile.name);
  //       createAssignment.append('pdffile', myFile);
  //       createAssignment.append('lesson_id', toggleLessonId);

  //       let response = await createAssignmentForSingleLesson(createAssignment);

  //       if (response) {
  //         setToggleAddAssignmentButton(false);
  //       } else {
  //         setToggleAddAssignmentButton(false);
  //       }
  //     } else {
  //       setToggleAddContentButton(true);

  //       let createContent = new FormData();

  //       createContent.append('title', myFile.name);
  //       createContent.append('pdffile', myFile);
  //       createContent.append('lesson_id', toggleLessonId);

  //       let response = await createContentForSingleLesson(createContent);

  //       if (response) {
  //         setToggleAddContentButton(false);
  //       } else {
  //         setToggleAddContentButton(false);
  //       }
  //     }
  //   };

  const editCourseDescription = (e) => {
    setEditData({
      ...editData,
      description: courses.LessonListData?.description,
    });

    setEditCourseDescriptionModalShow(true);
  };

  const onChangeEditCourseDescription = (e) => {
    setEditData({
      ...editData,
      description: e.target.value,
    });
  };

  const changeToggleUI = async (e, name) => {
    e.preventDefault();

    // // // console.log(e.target.name);

    if (name === 'courseName') {
      setEditData({
        ...editData,
        course_name: courses.LessonListData.courseName,
      });
      setToggleEditName(!toggleEditName);
    } else if (name === 'description') {
      setEditData({
        ...editData,
        description: courses.LessonListData.description,
      });
      setToggleEditDescription(!toggleEditDescription);
    } else if (name === 'triggerThumbnailUpload') {
      document.getElementById('thumbnailFile').click();
    } else if (name === 'tumbnailUpload') {
      let myFile = e.target.files[0];
      setEditData({
        ...editData,
        thumbnail: myFile,
      });
      setToggleTumbnailConfirm(!toggleThumbnailConfirm);
    } else if (name === 'bannerUpload') {
      let myFile = e.target.files[0];
      // // // console.log(e.target.files, myFile);
      setEditData({
        ...editData,
        bannerImage: myFile,
      });

      var newBannerImage = new FormData();

      // // // console.log(editData.bannerImage);

      newBannerImage.append('banner_image', myFile);
      newBannerImage.append('course_id', match.params.id);

      const hehe = await editCourseName(
        newBannerImage,
        myFile.name,
        match.params.id,
        'banner'
      );

      if (hehe === true) {
        // setToggleTumbnailConfirm(!toggleThumbnailConfirm);
        document.getElementById('coverFile').value = null;
      } else {
        // setToggleTumbnailConfirm(!toggleThumbnailConfirm);
        document.getElementById('coverFile').value = null;
      }
      //   edit_course_name(e, 'banner');
      // setToggleTumbnailConfirm(!toggleThumbnailConfirm);
    }
  };

  const openCoverUpload = (e) => {
    e.preventDefault();

    document.getElementById('coverFile').click();
  };

  const changeCourseName = (e) => {
    e.preventDefault();

    setEditData({
      course_name: e.target.value,
    });
  };

  const edit_course_name = async (e, source) => {
    e.preventDefault();

    if (source === 'name') {
      var newCourseName = new FormData();

      newCourseName.append('name', editData.course_name);
      newCourseName.append('course_id', match.params.id);

      const hehe = await editCourseName(
        newCourseName,
        editData.course_name,
        match.params.id,
        'name'
      );

      if (hehe === true) {
        setToggleEditName(!toggleEditName);
      } else {
        setToggleEditName(!toggleEditName);
      }
    } else if (source === 'banner') {
      var newBannerImage = new FormData();

      // // // console.log(editData.bannerImage);

      newBannerImage.append('banner_image', editData.bannerImage);
      newBannerImage.append('course_id', match.params.id);

      const hehe = await editCourseName(
        newBannerImage,
        editData.bannerImage.name,
        match.params.id,
        'banner'
      );

      if (hehe === true) {
        // setToggleTumbnailConfirm(!toggleThumbnailConfirm);
        document.getElementById('coverFile').value = null;
      } else {
        // setToggleTumbnailConfirm(!toggleThumbnailConfirm);
        document.getElementById('coverFile').value = null;
      }
    } else if (source === 'description') {
      var newDescription = new FormData();

      // // // console.log(editData.bannerImage);

      newDescription.append('description', editData.description);
      newDescription.append('course_id', match.params.id);

      const hehe = await editCourseName(
        newDescription,
        editData.description,
        match.params.id,
        'description'
      );

      if (hehe === true) {
        setEditCourseDescriptionModalShow(!editCourseDescriptionModalShow);
      } else {
        setEditCourseDescriptionModalShow(!editCourseDescriptionModalShow);
      }
    } else {
      var newThumbnail = new FormData();

      newThumbnail.append('image', editData.thumbnail);
      newThumbnail.append('course_id', match.params.id);

      const hehe = await editCourseName(
        newThumbnail,
        editData.thumbnail.name,
        match.params.id,
        'image'
      );

      if (hehe === true) {
        setToggleTumbnailConfirm(!toggleThumbnailConfirm);
      } else {
        setToggleTumbnailConfirm(!toggleThumbnailConfirm);
      }
    }
  };

  const onChangeQuizFields = (e) => {
    e.preventDefault();

    setQuizData({
      ...quizData,
      [e.target.name]: e.target.value,
    });
  };

  //   const onChangeAssignmentFields = (e) => {
  //     e.preventDefault();

  //     if (e.target.name === 'name') {
  //       setAssignmentData({
  //         ...assignmentData,
  //         name: e.target.value,
  //       });
  //     } else {
  //       setAssignmentData({
  //         ...assignmentData,
  //         assignmentFile: e.target.files[0],
  //       });
  //     }
  //   };

  const onChangeAssignmentNameField = (e) => {
    e.preventDefault();

    setAssignmentName(e.target.value);
  };

  const onChangeContentFields = (e) => {
    e.preventDefault();

    if (e.target.name === 'name') {
      setContentData({
        ...contentData,
        name: e.target.value,
      });
    } else {
      setContentData({
        ...contentData,
        contentFile: e.target.files[0],
      });
    }
  };

  const onChangeVideoFields = (e) => {
    e.preventDefault();

    // setVideoData({
    //   ...videoData,
    //   [e.target.name]: e.target.value,
    // });

    if (e.target.name === 'videoFile') {
      setVideoData({
        ...videoData,
        videoFile: e.target.files[0],
      });
    } else {
      setVideoData({
        ...videoData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const addNewQuiz = async (e) => {
    e.preventDefault();

    setToggleAddQuizButton(true);

    setModalShow(false);

    let createQuiz = new FormData();

    // // // // console.log(quizData);

    createQuiz.append('title', quizData.name);
    createQuiz.append('link', quizDataLink);
    // createQuiz.append('link', textQuill);
    createQuiz.append('lesson_id', toggleLessonId);
    createQuiz.append('course_id', match.params.id); //For cache

    let response = await createNewQuiz(createQuiz);

    if (response) {
      let notificationData = {
        messageBody:
          quizData.name +
          ' added in ' +
          toggleLessonName +
          ' of ' +
          courses.LessonListData.courseName,
        title: 'Loop',
        image: courses.LessonListData.image,
        // IMAGE_URL_ENDPOINT + ,
      };

      sendNotification(notificationData);
      setToggleAddQuizButton(false);
    } else {
      setToggleAddQuizButton(false);
    }
  };

  //   const addNewAssignment = async (e) => {
  //     e.preventDefault();

  //     setToggleAddAssignmentButton(true);

  //     setAssignmentModalShow(false);

  //     let createAssignment = new FormData();

  //     createAssignment.append('title', assignmentData.name);
  //     createAssignment.append('pdffile', assignmentData.assignmentFile);
  //     createAssignment.append('lesson_id', toggleLessonId);
  //     createAssignment.append('course_id', match.params.id); //For cache

  //     let response = await createAssignmentForSingleLesson(createAssignment);

  //     if (response) {
  //       let notificationData = {
  //         messageBody:
  //           assignmentData.name +
  //           ' added in ' +
  //           toggleLessonName +
  //           ' of ' +
  //           courses.LessonListData.courseName,
  //         title: 'GetLoop',
  //         image: IMAGE_URL_ENDPOINT + courses.LessonListData.image,
  //       };

  //       sendNotification(notificationData);

  //       setToggleAddAssignmentButton(false);
  //     } else {
  //       setToggleAddAssignmentButton(false);
  //     }
  //   };

  const addNewAssignment = async (e) => {
    e.preventDefault();

    setToggleAddAssignmentButton(true);

    setAssignmentModalShow(false);

    let createAssignment = new FormData();

    createAssignment.append('title', assignmentName);
    createAssignment.append('pdffile', assignmentDataLink);
    createAssignment.append('lesson_id', toggleLessonId);
    createAssignment.append('course_id', match.params.id); //For cache

    let response = await createAssignmentForSingleLesson(createAssignment);

    if (response) {
      let notificationData = {
        messageBody:
          assignmentName +
          ' added in ' +
          toggleLessonName +
          ' of ' +
          courses.LessonListData.courseName,
        title: 'Loop',
        image: courses.LessonListData.image,
        // IMAGE_URL_ENDPOINT + ,
      };

      sendNotification(notificationData);

      setToggleAddAssignmentButton(false);
    } else {
      setToggleAddAssignmentButton(false);
    }
  };

  const addNewContent = async (e) => {
    e.preventDefault();

    setToggleAddContentButton(true);

    setContentModalShow(false);

    let createContent = new FormData();

    createContent.append('title', contentData.name);
    createContent.append('pdffile', contentData.contentFile);
    createContent.append('lesson_id', toggleLessonId);
    createContent.append('course_id', match.params.id); //For cache

    let response = await createContentForSingleLesson(createContent);

    if (response) {
      let notificationData = {
        messageBody:
          contentData.name +
          ' added in ' +
          toggleLessonName +
          ' of ' +
          courses.LessonListData.courseName,
        title: 'Loop',
        image: courses.LessonListData.image,
        // IMAGE_URL_ENDPOINT + ,
      };

      sendNotification(notificationData);
      setToggleAddContentButton(false);
    } else {
      setToggleAddContentButton(false);
    }
  };

  const addNewVideo = async (e) => {
    e.preventDefault();

    // setToggleAddContentButton(true);

    setAddNewVideoUI(false);

    let createVideo = new FormData();

    createVideo.append('video_title', videoData.title);
    createVideo.append('video_description', videoData.videoDescription);
    createVideo.append('video_file', videoData.videoFile);
    createVideo.append('lesson_id', toggleLessonId);
    createVideo.append('course_id', match.params.id); //For cache

    for (var value of createVideo.values()) {
      // // // console.log(value);
    }

    for (var pair of createVideo.entries()) {
      // // // console.log(pair[0] + ', ' + pair[1]);
    }

    // let response = await createContentForSingleLesson(createContent);

    let video = await createVideoForSingleLesson(createVideo);

    if (video) {
      let notificationData = {
        messageBody:
          videoData.title +
          ' added in ' +
          toggleLessonName +
          ' of ' +
          courses.LessonListData.courseName,
        title: 'Loop',
        image: courses.LessonListData.image,
        // IMAGE_URL_ENDPOINT + courses.LessonListData.image,
      };
      setVideoData({
        title: '',
        videoDescription: '',
        videoFile: null,
      });

      sendNotification(notificationData);
    } else {
      setVideoData({
        title: '',
        videoDescription: '',
        videoFile: null,
      });
    }
  };

  const deleteQuiz = (e, id) => {
    e.preventDefault();

    let deleteeQuiz = new FormData();

    deleteeQuiz.append('lesson_id', toggleLessonId);
    deleteeQuiz.append('course_id', match.params.id); //For cache

    delete_quiz(deleteeQuiz, id);
  };

  //   useEffect(() => {
  //     // you will get updated finalData here, each time it changes
  //     setQuizData(quizData);
  //     // // // console.log('asdasdasd', quizData);
  //     // you can trigger your function from here
  //   }, [quizData]);

  const editQuiz = async (e, id) => {
    e.preventDefault();

    //   Get Single Quiz record

    // // // console.log('Quiz ID: ', id);

    const getQuiz = await getSingleRecordQuiz(id);

    if (getQuiz.status) {
      // // // console.log(getQuiz.data);

      setQuizData({
        name: getQuiz.data.title,
      });

      //   setQuizDataLink(get)

      setQuizId(getQuiz.data._id);

      setSingleQuizContent(getQuiz.data.link);

      //   // // // console.log('quiz data set: ', quizData);

      setToggleUpdateQuizButton(true);
      setModalShow(true);
    }
  };

  const editAssignment = async (e, id) => {
    e.preventDefault();

    //   Get Single Quiz record

    // // // console.log('Assignment ID: ', id);

    const getAssignment = await getSingleRecordAssignment(id);

    if (getAssignment.status) {
      // // // console.log(getAssignment.data);

      setAssignmentName(getAssignment.data.title);

      //   setQuizDataLink(get)

      setAssignmentId(getAssignment.data._id);

      setSingleAssignmentContent(getAssignment.data.pdffile);

      //   // // // console.log('quiz data set: ', quizData);

      setToggleUpdateAssignmentButton(true);
      setAssignmentModalShow(true);
    }
  };

  const updateQuiz = async (e) => {
    e.preventDefault();

    // // // console.log('Updated Quiz Data: ', quizData);

    // setToggleAddQuizButton(true);

    setQuizData({
      name: '',
      link: '',
    });

    setSingleQuizContent('');

    //   // // // console.log('quiz data set: ', quizData);

    setToggleUpdateQuizButton(false);
    setModalShow(false);

    let updateQuiz = new FormData();

    updateQuiz.append('title', quizData.name);
    updateQuiz.append('link', quizDataLink);
    // createQuiz.append('link', textQuill);
    updateQuiz.append('lesson_id', toggleLessonId);
    updateQuiz.append('course_id', match.params.id); //For cache

    let response = await updateQuizContent(updateQuiz, quizID);

    if (response) {
      //   let notificationData = {
      //     messageBody:
      //       quizData.name +
      //       ' added in ' +
      //       toggleLessonName +
      //       ' of ' +
      //       courses.LessonListData.courseName,
      //     title: 'GetLoop',
      //     image: IMAGE_URL_ENDPOINT + courses.LessonListData.image,
      //   };
      //   sendNotification(notificationData);
      //   setToggleAddQuizButton(false);
    } else {
      //   setToggleAddQuizButton(false);
    }
  };

  const updateAssignment = async (e) => {
    e.preventDefault();

    // // // // console.log('Updated Assignment Data: ', assiData);

    // setToggleAddQuizButton(true);

    // setQuizData({
    //   name: '',
    //   link: '',
    // });

    setAssignmentName('');

    setSingleAssignmentContent('');

    //   // // // console.log('quiz data set: ', quizData);

    setToggleUpdateAssignmentButton(false);
    setAssignmentModalShow(false);

    let updateAssignment = new FormData();

    updateAssignment.append('title', assignmentName);
    updateAssignment.append('pdffile', assignmentDataLink);
    // createQuiz.append('link', textQuill);
    updateAssignment.append('lesson_id', toggleLessonId);
    updateAssignment.append('course_id', match.params.id); //For cache

    let response = await updateAssignmentContent(
      updateAssignment,
      assignmentID
    );

    if (response) {
      //   let notificationData = {
      //     messageBody:
      //       quizData.name +
      //       ' added in ' +
      //       toggleLessonName +
      //       ' of ' +
      //       courses.LessonListData.courseName,
      //     title: 'GetLoop',
      //     image: IMAGE_URL_ENDPOINT + courses.LessonListData.image,
      //   };
      //   sendNotification(notificationData);
      //   setToggleAddQuizButton(false);
    } else {
      //   setToggleAddQuizButton(false);
    }
  };

  const deleteContent = (e, id) => {
    e.preventDefault();

    let deleteeContent = new FormData();

    deleteeContent.append('lesson_id', toggleLessonId);
    deleteeContent.append('course_id', match.params.id); //For cache

    delete_content(deleteeContent, id);
  };

  const deleteAssignment = async (e, id) => {
    e.preventDefault();

    let deleteeAssignment = new FormData();

    deleteeAssignment.append('lesson_id', toggleLessonId);
    deleteeAssignment.append('course_id', match.params.id); //For cache

    let test = await delete_assignment(deleteeAssignment, id);

    if (test === true) {
      // // // console.log('my name is anthony gonsalves: ',courses.singleLessonAssignments);
    }
  };

  const deleteVideo = async (e, id) => {
    e.preventDefault();

    let deleteeVideo = new FormData();

    deleteeVideo.append('lesson_id', toggleLessonId);
    deleteeVideo.append('course_id', match.params.id); //For cache

    await delete_video(deleteeVideo, id);

    // if (test === true) {
    //   // // // console.log(
    //     'my name is anthony gonsalves: ',
    //     courses.singleLessonAssignments
    //   );
    // }
  };

  const deleteLesson = async (e, id) => {
    e.preventDefault();

    // // // console.log('Delete Lesson ID: ', id);

    let deleteeLesson = new FormData();

    deleteeLesson.append('lesson_id', toggleLessonId);
    deleteeLesson.append('course_id', match.params.id); //For cache

    let test = await delete_lesson(deleteeLesson, id);

    if (test) {
      setToggleLessonId(null);
    } else {
      setToggleLessonId(null);
    }
  };

  const createExistingVideo = (e) => {
    e.preventDefault();

    let data = new FormData();

    data.append('lesson_id', toggleLessonId);
    data.append('course_id', match.params.id); //For cache
    data.append('video_id', videoId);

    setVideoId('');
    setModalLessonId('');
    setAddExistingVideoUI(false);

    createVideoForSingleLesson(data);
  };

  const setEditLessonUI = async (e, id, name) => {
    e.preventDefault();

    await setToggleEditLessonInput(false);
    await setCheckLessonID(id);
    await setEditLesson(name);
    await setToggleEditLessonInput(true);
  };

  const setEditVideoDataUI = async (e, id, title, description) => {
    e.preventDefault();

    await setToggleEditVideoTitleInput(false);
    await setCheckVideoID(id);
    await setEditVideoData({
      editTitle: title,
      editDescription: description,
    });
    await setToggleEditVideoTitleInput(true);
  };

  const onChangeLicense = async (e) => {
    // // // console.log(e);

    // await setLoadingSwitch(true);

    var EditStatusData = new FormData();

    setSwitchStatus(e);

    EditStatusData.append('status_subscription', e === true ? '1' : '0');
    // EditLessonData.append('', checkLessonID);
    EditStatusData.append('course_id', match.params.id);

    // // // // console.log('sadasd', loadingSwitch);

    let response = await editLessonName(
      EditStatusData,
      e,
      toggleLessonId,
      'changeStatus'
    );

    // if (response) {
    //   setLoadingSwitch(false);
    //   // // // console.log('after', loadingSwitch);
    // } else {
    //   setLoadingSwitch(false);
    // }
  };

  return (
    <div
      className={
        toggleNavbarBurger
          ? 'content-inner custom-height'
          : 'content-inner custom-height active'
      }
    >
      {courses.LessonListData !== null ? (
        <Fragment>
          <section
            className='top-section'
            style={
              courses.LessonListData.banner_image !== ''
                ? {
                    backgroundImage: `url(
                                ${
                                  //   IMAGE_URL_ENDPOINT +
                                  courses.LessonListData.banner_image
                                }
                              )`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '300px',
                    position: 'relative',
                  }
                : { background: 'black', height: '300px', position: 'relative' }
            }
          >
            <div className='overlay-banner'></div>
            <div className='upload-cover'>
              <input
                type='file'
                className='upload-cover-file'
                id='coverFile'
                // value={editData.bannerImage}
                onChange={(e) => changeToggleUI(e, 'bannerUpload')}
              />
              <button
                className='btn btn-gradient-01'
                onClick={(e) => openCoverUpload(e)}
              >
                Upload Cover
              </button>
              {/* <Icofont
                className='upload-cover-icon'
                icon='pencil-alt-5'
                size='2'
                onClick={(e) => changeToggleUI(e, 'triggerThumbnailUpload')}
              /> */}
            </div>
            <div className='edit-description'>
              <button
                className='btn btn-gradient-01'
                onClick={(e) => editCourseDescription(e)}
              >
                Edit Description
              </button>
            </div>
            <div className='container-fluid h-100'>
              <div className='row mt-1 h-100'>
                {/* <div className='col-md-3'></div> */}
                <div className='col-md-12 h-100'>
                  <div className='display-flex test'>
                    <div className='course-title position-relative'>
                      <img
                        src={
                          // IMAGE_URL_ENDPOINT +
                          courses.LessonListData.image
                        }
                        width='40'
                        alt='Sorry For Inconvenience'
                      />
                      {/* <span> */}
                      <input
                        type='file'
                        className='thumbnail-edit-file-input'
                        id='thumbnailFile'
                        // value={editData.thumbnail}
                        onChange={(e) => changeToggleUI(e, 'tumbnailUpload')}
                      />
                      <Icofont
                        className='edit-icon thumbnail'
                        icon='pencil-alt-5'
                        size='2'
                        onClick={(e) =>
                          changeToggleUI(e, 'triggerThumbnailUpload')
                        }
                      />
                      {/* </span> */}
                    </div>
                    <div>
                      {toggleEditName ? (
                        <div className='input'>
                          <input
                            type='text'
                            value={editData.course_name}
                            className='form-control'
                            style={{
                              width:
                                (editData.course_name.length + 1) * 8 + 'px',
                            }}
                            onKeyPress={(e) => {
                              e.target.style.width =
                                (e.target.value.length + 1) * 8 + 'px';
                            }}
                            onChange={(e) => changeCourseName(e)}
                          />
                          <Icofont
                            icon='tick-mark'
                            //   size='1.5'
                            style={{
                              fontSize: '1.5em',
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'white',
                            }}
                            onClick={(e) => edit_course_name(e, 'name')}
                          />
                          <Icofont
                            icon='close'
                            //   size='1.5'
                            style={{
                              fontSize: '1.5em',
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'white',
                            }}
                            onClick={(e) => changeToggleUI(e, 'courseName')}
                          />
                        </div>
                      ) : (
                        <Fragment>
                          <div className='course-title d-inline-block position-relative'>
                            <h1>{courses.LessonListData.courseName}</h1>
                            {/* <span> */}
                            <Icofont
                              className='edit-icon course'
                              icon='pencil-alt-5'
                              size='2'
                              onClick={(e) => changeToggleUI(e, 'courseName')}
                            />
                            {/* </span> */}
                          </div>
                        </Fragment>
                      )}
                      {/* {toggleEditDescription ? (
                        <div className='input'>
                          <input
                            type='text'
                            value={editData.description}
                            className='form-control'
                            style={{
                              width:
                                (editData.description.length + 1) * 8 + 'px',
                            }}
                            onKeyPress={(e) => {
                              e.target.style.width =
                                (e.target.value.length + 1) * 8 + 'px';
                            }}
                            onChange={(e) => changeCourseName(e)}
                          />
                          <Icofont
                            icon='tick-mark'
                            //   size='1.5'
                            style={{
                              fontSize: '1.5em',
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'white',
                            }}
                            onClick={(e) => edit_course_name(e, 'description')}
                          />
                          <Icofont
                            icon='close'
                            style={{
                              fontSize: '1.5em',
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'white',
                            }}
                            onClick={(e) => changeToggleUI(e, 'description')}
                          />
                        </div>
                      ) : (
                        <div className='course-title  position-relative'>
                          <p>{courses.LessonListData.description}</p>
                          <Icofont
                            className='edit-icon description'
                            icon='pencil-alt-5'
                            size='2'
                            onClick={(e) => changeToggleUI(e, 'description')}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {!courses.loadingLessons && Lessons !== null ? (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='lessons-sidebar'>
                    <ul className='list-group rounded-0'>
                      {loadingReorderingLessons ? (
                        <div className='overlay'>
                          <Icofont icon='spinner' size='3' spin='true' />
                        </div>
                      ) : null}

                      <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                        <Droppable droppableId='droppable'>
                          {(provided, snapshot) => (
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className='list-group rounded-0'
                              //   style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {Lessons.map((item, index) => (
                                <Fragment key={index}>
                                  {toggleEditLessonInput &&
                                  item._id === checkLessonID ? (
                                    <li className='list-group-item'>
                                      <input
                                        type='text'
                                        name='EditLesson'
                                        value={editLesson}
                                        onChange={(e) =>
                                          setEditLesson(e.target.value)
                                        }
                                      />
                                      <i
                                        className='icofont-tick-mark'
                                        style={{
                                          marginLeft: '10px',
                                          color: 'black',
                                        }}
                                        onClick={(e) => addEditLesson(e)}
                                      ></i>
                                      <i
                                        className='icofont-close'
                                        style={{
                                          marginLeft: '10px',
                                          color: 'black',
                                        }}
                                        onClick={(e) => closeEditLesson(e)}
                                      ></i>
                                    </li>
                                  ) : (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <li
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={
                                            toggleLessonId === item._id
                                              ? 'list-group-item active'
                                              : 'list-group-item'
                                          }
                                          // style={{ position: 'relative' }}
                                          key={index}
                                          onClick={(e) =>
                                            getLessonDetail(
                                              e,
                                              item._id,
                                              item.name,
                                              item.status_subscription
                                            )
                                          }
                                          //   style={getItemStyle(
                                          //     snapshot.isDragging,
                                          //     provided.draggableProps.style
                                          //   )}
                                        >
                                          <i className='icofont-ebook'></i>{' '}
                                          {item.name}
                                          <i
                                            className='icofont-ui-edit'
                                            style={
                                              toggleLessonId === item._id
                                                ? {
                                                    color: 'white',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                  }
                                                : {
                                                    color: 'black',
                                                    float: 'right',
                                                    cursor: 'pointer',
                                                  }
                                            }
                                            onClick={(e) =>
                                              setEditLessonUI(
                                                e,
                                                item._id,
                                                item.name
                                              )
                                            }
                                          ></i>
                                        </li>
                                      )}
                                    </Draggable>
                                  )}
                                </Fragment>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {/* {courses.LessonListData.lessons.map((data, index) => (
                        <Fragment>
                          {toggleEditLessonInput &&
                          data._id === checkLessonID ? (
                            <li className='list-group-item'>
                              <input
                                type='text'
                                name='EditLesson'
                                value={editLesson}
                                onChange={(e) => setEditLesson(e.target.value)}
                              />
                              <i
                                className='icofont-tick-mark'
                                style={{ marginLeft: '10px', color: 'black' }}
                                onClick={(e) => addEditLesson(e)}
                              ></i>
                              <i
                                className='icofont-close'
                                style={{ marginLeft: '10px', color: 'black' }}
                                onClick={(e) => closeEditLesson(e)}
                              ></i>
                            </li>
                          ) : (
                            <Fragment>
                              <li
                                className={
                                  toggleLessonId === data._id
                                    ? 'list-group-item active'
                                    : 'list-group-item'
                                }
                                // style={{ position: 'relative' }}
                                key={index}
                                onClick={(e) =>
                                  getLessonDetail(e, data._id, data.name)
                                }
                              >
                                <i className='icofont-ebook'></i> {data.name}
                                <i
                                  className='icofont-ui-edit'
                                  style={
                                    toggleLessonId === data._id
                                      ? { color: 'white', float: 'right' }
                                      : { color: 'black', float: 'right' }
                                  }
                                  onClick={(e) =>
                                    setEditLessonUI(e, data._id, data.name)
                                  }
                                ></i>
                              </li>
                            </Fragment>
                          )}
                        </Fragment>
                      ))} */}
                      {toggleAddLessonInput ? (
                        <li className='list-group-item'>
                          <input
                            type='text'
                            name='addLesson'
                            onChange={(e) => setLessonName(e.target.value)}
                          />
                          <i
                            className='icofont-tick-mark'
                            style={{ marginLeft: '10px', color: 'black' }}
                            onClick={(e) => addNewLesson(e)}
                          ></i>
                          <i
                            className='icofont-close'
                            style={{ marginLeft: '10px', color: 'black' }}
                            onClick={(e) => closeNewLesson(e)}
                          ></i>
                        </li>
                      ) : null}

                      {lessonLoader ? (
                        <li className='list-group-item'>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Icofont icon='spinner' size='3' spin='true' />
                          </div>
                        </li>
                      ) : null}

                      <li
                        className='list-group-item'
                        onClick={(e) => addToggleLesson(e)}
                        style={
                          toggleAddLessonInput || lessonLoader
                            ? { pointerEvents: 'none', cursor: 'normal' }
                            : null
                        }
                      >
                        <i className='icofont-plus'></i> Add Lesson
                      </li>
                      {/* <li className='list-group-item'>
                             <i className='icofont-ebook'></i> Lesson #2
                           </li>
                           <li className='list-group-item'>
                             <i className='icofont-ebook'></i> Lesson #3
                           </li>
                           <li className='list-group-item'>
                             <i className='icofont-ebook'></i> Lesson #4
                           </li>
                           <li className='list-group-item'>
                             <i className='icofont-ebook'></i> Lesson #5
                           </li>
                           <li className='list-group-item'>
                             <i className='icofont-plus'></i> Add Lesson
                           </li> */}
                    </ul>
                  </div>
                </div>

                <div
                  className='col-md-9  bg-white shadow p-5'
                  style={{
                    maxHeight: 'calc(100vh - 430px)',
                    minHeight: 'calc(100vh - 430px)',
                    overflowY: 'auto',
                  }}
                >
                  {toggleLessonId !== null ? (
                    <div className='row edit-button-section'>
                      <div className='col-md-12'>
                        <div className='button'>
                          <button
                            className='btn btn-gradient-01 mr-2'
                            onClick={(e) => deleteLesson(e, toggleLessonId)}
                          >
                            Delete Lesson
                          </button>
                          <BootstrapSwitchButton
                            // onstyle='outline-success'
                            // offstyle='outline-danger'
                            size='lg'
                            checked={switchStatus}
                            onlabel='Subscribers Only'
                            offlabel='Free'
                            onChange={(e) => onChangeLicense(e)}
                            disabled={loadingSwitch}
                            width='185'
                            style={
                              loadingSwitch
                                ? 'switch-disabled'
                                : 'switch-enable'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <div className='row edit-button-section'>
                    <div className='col-md-12'></div>
                  </div> */}

                  {/* <Accordion defaultActiveKey='0'>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey='0'>
                        Videos
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='0'>
                        <Card.Body>
                          <div className='row videos-section'>
                            <div className='col-md-12'>
                              <div style={{ display: 'flex' }}>
                                <div>
                                  <h4 className='videos-title'>Videos</h4>
                                </div>
                                {toggleLessonId !== null ? (
                                  <Fragment>
                                    <div className='edit-button-section'>
                                      <div className='col-md-12'>
                                        <div className='button'>
                                          <button
                                            className='btn btn-gradient-01'
                                            onClick={(e) =>
                                              setAddNewVideoUI(true)
                                            }
                                          >
                                            Add Video
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='edit-button-section'>
                                      <div className='col-md-12'>
                                        <div className='button'>
                                          <button
                                            className='btn btn-gradient-01'
                                            onClick={(e) =>
                                              setAddExistingVideoUI(true)
                                            }
                                          >
                                            Add Exsisting Video
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                ) : null}
                              </div>

                            </div>
                            <div className='col-md-12'>
                              {courses.singleLessonVideos !== null &&
                              !courses.singleLessonVideosLoading &&
                              Videos !== null ? (
                      
                                <DragDropContext
                                  onDragEnd={(e) => onDragVideoEnd(e)}
                                >
                                  <Droppable
                                    droppableId='droppable'
                                    direction='horizontal'
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        style={{
                                          display: 'flex',
                                          overflow: 'auto',
                                          width: '100%',
                                          position: 'relative',
                                        }}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        {loadingReorderingVideos ? (
                                          <div className='overlay'>
                                            <Icofont
                                              icon='spinner'
                                              size='3'
                                              spin='true'
                                            />
                                          </div>
                                        ) : null}
                                        {Videos.map((data, index) => (
                                          
                                          <Draggable
                                            key={data.id}
                                            draggableId={data.id + 'asdsa'}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <div className='lessons-video'>
                                                  <iframe
                                                    src={
                                                      'https://iframe.videodelivery.net/' +
                                                      data.cloudflare_uid
                                                    }
                                                    title={data.video_title}
                                                    style={{
                                                      border: 'none',
                                                      borderRadius: '5px',
                                                    }}
                                                    width='100%'
                                                    height='100%'
                                                    allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                                                    allowFullScreen={true}
                                                  ></iframe>
                                                  <Icofont
                                                    icon='close'
                                                    size='1.5'
                                                    className='delete-video'
                                                    onClick={(e) =>
                                                      deleteVideo(e, data.id)
                                                    }
                                                  
                                                  />
                                                </div>
                                                <div className='video-title'>
                                                  <p>{data.video_title}</p>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
             
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              ) : // </Swiper>
                              courses.singleLessonVideosLoading ? (
                                <div
                                  style={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <div>
                                  
                                    <Icofont
                                      icon='spinner'
                                      size='3'
                                      spin='true'
                                    />
                                    <div>
                                      <h4
                                        style={{
                                          letterSpacing: '2px',
                                          marginTop: '5px',
                                        }}
                                      >
                                        {courses.progress}%
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey='1'>
                        Quizes
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='1'>
                        <Card.Body>Hello! I'm another body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion> */}
                  <div className='row videos-section'>
                    <div className='col-md-12'>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <h4 className='videos-title'>Videos</h4>
                        </div>
                        {toggleLessonId !== null ? (
                          <Fragment>
                            <div className='edit-button-section'>
                              <div className='col-md-12'>
                                <div className='button'>
                                  <button
                                    className='btn btn-gradient-01'
                                    onClick={(e) => setAddNewVideoUI(true)}
                                  >
                                    Add Video
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className='edit-button-section'>
                              <div className='col-md-12'>
                                <div className='button'>
                                  <button
                                    className='btn btn-gradient-01'
                                    onClick={(e) => setAddExistingVideoUI(true)}
                                  >
                                    Add Exsisting Video
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>

                      {/* <h4 className='videos-title'>Working Videos</h4> */}
                    </div>
                    <div className='col-md-12'>
                      {courses.singleLessonVideos !== null &&
                      !courses.singleLessonVideosLoading &&
                      Videos !== null ? (
                        // <Swiper
                        //   spaceBetween={20}
                        //   slidesPerView={1}
                        //   // onSlideChange={() => // // // console.log('slide change')}
                        //   // onSwiper={(swiper) => // // // console.log(swiper)}
                        //   navigation
                        //   // autoplay={true}
                        //   // loop={true}
                        //   pagination={{ clickable: true }}
                        //   breakpoints={{
                        //     // when window width is >= 640px
                        //     640: {
                        //       slidesPerView: 1,
                        //       spaceBetween: 20,
                        //     },
                        //     768: {
                        //       slidesPerView: 2,
                        //       spaceBetween: 20,
                        //     },
                        //     1024: {
                        //       slidesPerView: 3,
                        //       spaceBetween: 20,
                        //     },
                        //     1300: {
                        //       slidesPerView: 4,
                        //       spaceBetween: 20,
                        //     },
                        //   }}
                        // >
                        <DragDropContext onDragEnd={(e) => onDragVideoEnd(e)}>
                          <Droppable
                            droppableId='droppable'
                            direction='horizontal'
                          >
                            {(provided, snapshot) => (
                              <div
                                style={{
                                  display: 'flex',
                                  overflow: 'auto',
                                  width: '100%',
                                  position: 'relative',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {loadingReorderingVideos ? (
                                  <div className='overlay'>
                                    <Icofont
                                      icon='spinner'
                                      size='3'
                                      spin='true'
                                    />
                                  </div>
                                ) : null}
                                {Videos.map((data, index) => (
                                  // <SwiperSlide key={index}>
                                  <Draggable
                                    key={data.id}
                                    draggableId={data.id + 'asdsa'}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className='lessons-video'>
                                          <video
                                            // class=''
                                            loop
                                            controls
                                            width='100%'
                                            height='100%'
                                            src={data.cloudflare_preview}
                                            style={{
                                              border: 'none',
                                              objectFit: 'cover',
                                            }}
                                            muted
                                          ></video>
                                          {/* <iframe
                                            src={
                                              // 'https://iframe.videodelivery.net/' +
                                              data.cloudflare_preview +
                                              '?autoplay=false'
                                            }
                                            title={data.video_title}
                                            style={{
                                              border: 'none',
                                              borderRadius: '5px',
                                            }}
                                            width='100%'
                                            height='100%'
                                            allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                                            allowFullScreen={true}></iframe> */}
                                          <Icofont
                                            icon='close'
                                            size='1.5'
                                            className='delete-video'
                                            onClick={(e) =>
                                              deleteVideo(e, data.id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </div>
                                        <div
                                          className='video-title'
                                          style={{
                                            width: '300px',
                                            margin: 'auto',
                                          }}
                                        >
                                          {toggleEditVideoTitleInput &&
                                          data.id === checkVideoID ? (
                                            <Fragment>
                                              <input
                                                type='text'
                                                name='editTitle'
                                                value={editVideoData.editTitle}
                                                style={{ width: '100%' }}
                                                onChange={(e) =>
                                                  setEditVideoData({
                                                    ...editVideoData,
                                                    [e.target.name]:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </Fragment>
                                          ) : (
                                            <Fragment>
                                              <Icofont
                                                icon='edit'
                                                size='1.5'
                                                className='edit-video-title'
                                                onClick={(e) =>
                                                  setEditVideoDataUI(
                                                    e,
                                                    data.id,
                                                    data.video_title,
                                                    data.video_description
                                                  )
                                                }
                                                //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                              />
                                              <p>{data.video_title}</p>
                                            </Fragment>
                                          )}
                                          <Icofont
                                            icon='close'
                                            size='1.5'
                                            className='delete-video'
                                            onClick={(e) =>
                                              deleteVideo(e, data.id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </div>
                                        <div
                                          className='video-title'
                                          style={{
                                            width: '300px',
                                            margin: 'auto',
                                          }}
                                        >
                                          {toggleEditVideoTitleInput &&
                                          data.id === checkVideoID ? (
                                            <Fragment>
                                              <textarea
                                                type='text'
                                                name='editDescription'
                                                style={{
                                                  width: '100%',
                                                  marginTop: '10px',
                                                }}
                                                value={
                                                  editVideoData.editDescription
                                                }
                                                onChange={(e) =>
                                                  setEditVideoData({
                                                    ...editVideoData,
                                                    [e.target.name]:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                              <i
                                                className='icofont-tick-mark'
                                                style={{
                                                  marginLeft: '10px',
                                                  color: 'black',
                                                }}
                                                onClick={(e) => addEditTitle(e)}
                                              ></i>
                                              <i
                                                className='icofont-close'
                                                style={{
                                                  marginLeft: '10px',
                                                  color: 'black',
                                                }}
                                                onClick={(e) =>
                                                  closeEditTitle(e)
                                                }
                                              ></i>
                                            </Fragment>
                                          ) : (
                                            <p>{data.video_description}</p>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                  // </SwiperSlide>
                                ))}
                                {/* <SwiperSlide> */}
                                {/* <div
                            className='lessons-video'
                            onClick={(e) => setAddNewVideoUI(true)}
                          >
                            <div className='add-video'>
                              
                              <p>Add Video</p>
                            </div>
                            
                          </div> */}
                                {/* </SwiperSlide> */}

                                {/* <SwiperSlide> */}
                                {/* <div
                            className='lessons-video'
                            onClick={(e) => setAddExistingVideoUI(true)}
                          >
                            <div className='add-video'>
                              
                              <p>Add Existing Video</p>
                            </div>
                           
                          </div> */}
                                {/* </SwiperSlide> */}

                                {/* <SwiperSlide>
                                        <div className='lessons-video'>
                                          <iframe
                                            src='https://iframe.videodelivery.net/267651907eb4719f3d0afff06a4f3772'
                                            style={{ border: 'none', borderRadius: '5px' }}
                                            width='100%'
                                            height='100%'
                                            allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                                            allowfullscreen='true'
                                          ></iframe>
                                        </div>
                                      </SwiperSlide>
                                      <SwiperSlide>
                                        <div className='lessons-video'>
                                          <div className='add-video'>
                                            <i className='icofont-plus'></i>
                                            <p>Add Videos</p>
                                          </div>
                                          <input
                                            type='file'
                                            className='video-file'
                                            id='videoInput'
                                            name='videofile'
                                          />
                                        </div>
                                      </SwiperSlide> */}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : // </Swiper>
                      courses.singleLessonVideosLoading ? (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <div>
                            {/* <span className='icofont-spinner'></span> */}
                            <Icofont icon='spinner' size='3' spin='true' />
                            <div>
                              <h4
                                style={{
                                  letterSpacing: '2px',
                                  marginTop: '5px',
                                }}
                              >
                                {courses.progress}%
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <Swipericofont-plus
                                 spaceBetween={20}
                                 slidesPerView={1}
           
                                 navigation
           
                                 pagination={{ clickable: true }}
                                 breakpoints={{
                                   // when window width is >= 640px
                                   640: {
                                     slidesPerView: 1,
                                     spaceBetween: 20,
                                   },
                                   768: {
                                     slidesPerView: 2,
                                     spaceBetween: 20,
                                   },
                                   1024: {
                                     slidesPerView: 3,
                                     spaceBetween: 20,
                                   },
                                   1300: {
                                     slidesPerView: 4,
                                     spaceBetween: 20,
                                   },
                                 }}
                               >
                                 <SwiperSlide zoom={true}>
                                   <div className='lessons-video'>
                                     <iframe
                                       src='https://iframe.videodelivery.net/267651907eb4719f3d0afff06a4f3772'
                                       style={{ border: 'none', borderRadius: '5px' }}
                                       width='100%'
                                       height='100%'
                                       allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                                       allowfullscreen='true'
                                     ></iframe>object
                                   </div>
                                 </SwiperSlide>
                                 <SwiperSlide>
                                   <div className='lessons-video'>
                                     <iframe
                                       src='https://iframe.videodelivery.net/267651907eb4719f3d0afff06a4f3772'
                                       style={{ border: 'none', borderRadius: '5px' }}
                                       width='100%'
                                       height='100%'
                                       allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                                       allowfullscreen='true'
                                     ></iframe>
                                   </div>toggleThumbnailConfirm
                                 </SwiperSlide>
                                 <SwiperSlide>
                                   <div className='lessons-video'>
                                     <div className='add-video'>
                                       <i className='icofont-plus'></i>
                                       <p>Add Videos</p>
                                     </div>
                                     <input
                                       type='file'
                                       className='video-file'
                                       id='videoInput'
                                       name='videofile'
                                     />
                                   </div>
                                 </SwiperSlide>
                               </Swiper> */}
                      {/* <div className="swiper-container videos-swiper">
                           <div className="swiper-wrapper">
                               <div className="swiper-slide">
                                   <div className="lessons-video">
                                       <iframe src="https://iframe.videodelivery.net/267651907eb4719f3d0afff06a4f3772" style="border: none;border-radius: 5px;" width="100%" height="100%" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                                   </div>
                               </div>
                               <div className="swiper-slide">
                                   <div className="lessons-video">
                                       <iframe src="https://iframe.videodelivery.net/267651907eb4719f3d0afff06a4f3772" style="border: none;border-radius: 5px;" width="100%" height="100%" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                                   </div>
                               </div>
                               <div className="swiper-slide">
                                   <div className="lessons-video">
                                       <div className="add-video">
                                           <i className="icofont-plus"></i>
                                           <p>Add Videos</p>
                                       </div>
                                       <input type="file" className="video-file" id="videoInput" name="videofile" />
                                   </div>
                               </div>
                           </div>
                           <div className="swiper-pagination"></div>
                           <div className="swiper-button-next"></div>
                           <div className="swiper-button-prev"></div>
                       </div> */}
                    </div>
                  </div>
                  <div className='row quizzes-section d-none'>
                    <div className='col-md-12'>
                      <h4 className='quizzes-title'>Quizes</h4>
                    </div>
                    <div className='col-md-12'>
                      <ul className='quizzes-list'>
                        {courses.singleLessonQuizes !== null &&
                        !courses.singleLessonQuizesLoading ? (
                          <Fragment>
                            {courses.singleLessonQuizes.length >= 1 ? (
                              <Fragment>
                                {courses.singleLessonQuizes.map(
                                  (data, index) => (
                                    // <div
                                    //   className='test'
                                    //   style={{ position: 'relative' }}
                                    // >
                                    <span
                                      //   href=''
                                      //   target='_blank'
                                      //   rel='noopener noreferrer'
                                      key={index}
                                      style={{ position: 'relative' }}
                                      className='test'
                                    >
                                      <li className='quiz-list'>
                                        {data.title}

                                        {/* {data.question} */}
                                      </li>
                                      <div className='options'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip
                                              wrapperStyle={{
                                                backgroundColor: 'black',
                                                color: 'white',
                                              }}
                                            >
                                              Delete Quiz
                                            </Tooltip>
                                          }
                                        >
                                          <Icofont
                                            icon='close'
                                            size='1.5'
                                            className='delete-quiz'
                                            onClick={(e) =>
                                              deleteQuiz(e, data._id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip
                                              wrapperStyle={{
                                                backgroundColor: 'black',
                                                color: 'white',
                                              }}
                                            >
                                              Edit Quiz
                                            </Tooltip>
                                          }
                                        >
                                          <Icofont
                                            icon='edit'
                                            size='1.5'
                                            className='edit-quiz'
                                            onClick={(e) =>
                                              editQuiz(e, data._id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </OverlayTrigger>
                                      </div>
                                    </span>

                                    // </div>

                                    // <li
                                    //   className='quiz-list'
                                    //   key={index}
                                    //   onClick={() => setModalShow(true)}
                                    // >
                                    //   {data.question}
                                    // </li>
                                  )
                                )}
                              </Fragment>
                            ) : null}

                            <li
                              className={
                                toggleAddQuizButton
                                  ? 'quiz-list add-quiz disable-button'
                                  : 'quiz-list add-quiz'
                              }
                              onClick={() => setModalShow(true)}
                            >
                              {toggleAddQuizButton ? (
                                <div
                                  style={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <Icofont
                                    icon='spinner'
                                    size='3'
                                    spin='true'
                                  />
                                </div>
                              ) : (
                                <>
                                  <Icofont icon='plus' /> Add Quiz
                                </>
                              )}
                              {/* <Icofont icon='plus' /> Add Quiz */}
                            </li>
                          </Fragment>
                        ) : courses.singleLessonQuizesLoading ? (
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Icofont icon='spinner' size='3' spin='true' />
                          </div>
                        ) : null}
                        {/* <li className='quiz-list'>Quiz #1</li>
                                 <li className='quiz-list'>Quiz #2</li>
                                 <li className='quiz-list add-quiz'>
                                   <Icofont icon='plus' /> Add Quiz
                                 </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className='row assignments-section d-none'>
                    <div className='col-md-12'>
                      <h4 className='assignments-title'>Assignments</h4>
                    </div>
                    <div className='col-md-12'>
                      <ul className='assignments-list'>
                        {courses.singleLessonAssignments !== null &&
                        !courses.singleLessonAssignmentsLoading ? (
                          <Fragment>
                            {courses.singleLessonAssignments.length >= 1 ? (
                              <Fragment>
                                {courses.singleLessonAssignments.map(
                                  (data, index) => (
                                    // <div
                                    //   className='test'
                                    //   style={{ position: 'relative' }}
                                    // >
                                    <span
                                      //   href={IMAGE_URL_ENDPOINT + data.pdffile}
                                      //   target='_blank'
                                      //   rel='noopener noreferrer'
                                      key={index}
                                      style={{ position: 'relative' }}
                                      className='testAssignment'
                                    >
                                      <li className='assignment-list'>
                                        {data.title}

                                        {/* {data.question} */}
                                      </li>
                                      <div className='options'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip
                                              wrapperStyle={{
                                                backgroundColor: 'black',
                                                color: 'white',
                                              }}
                                            >
                                              Delete Assignment
                                            </Tooltip>
                                          }
                                        >
                                          <Icofont
                                            icon='close'
                                            size='1.5'
                                            className='delete-assignment'
                                            onClick={(e) =>
                                              deleteAssignment(e, data._id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip
                                              wrapperStyle={{
                                                backgroundColor: 'black',
                                                color: 'white',
                                              }}
                                            >
                                              Edit Assignment
                                            </Tooltip>
                                          }
                                        >
                                          <Icofont
                                            icon='edit'
                                            size='1.5'
                                            className='edit-assignment'
                                            onClick={(e) =>
                                              editAssignment(e, data._id)
                                            }
                                            //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                          />
                                        </OverlayTrigger>
                                      </div>
                                      {/* <Icofont
                                        icon='close'
                                        size='1.5'
                                        className='delete-assignment'
                                        onClick={(e) =>
                                          deleteAssignment(e, data._id)
                                        }
                                        //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                      /> */}
                                    </span>

                                    // </div>

                                    // <li
                                    //   className='quiz-list'
                                    //   key={index}
                                    //   onClick={() => setModalShow(true)}
                                    // >
                                    //   {data.question}
                                    // </li>
                                  )
                                )}
                              </Fragment>
                            ) : null}

                            <li
                              className={
                                toggleAddAssignmentButton
                                  ? 'assignment-list add-assignment disable-button'
                                  : 'assignment-list add-assignment'
                              }
                              onClick={() => setAssignmentModalShow(true)}
                            >
                              {toggleAddAssignmentButton ? (
                                <div
                                  style={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <Icofont
                                    icon='spinner'
                                    size='3'
                                    spin='true'
                                  />
                                </div>
                              ) : (
                                <>
                                  <Icofont icon='plus' /> Add Assignment
                                </>
                              )}
                              {/* <Icofont icon='plus' /> Add Quiz */}
                            </li>
                          </Fragment>
                        ) : courses.singleLessonAssignmentsLoading ? (
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Icofont icon='spinner' size='3' spin='true' />
                          </div>
                        ) : null}

                        {/* {courses.singleLessonAssignments !== null &&
                                 !courses.singleLessonAssignmentsLoading ? (
                                   <Fragment>
                                     {courses.singleLessonAssignments.length >= 1 ? (
                                       <Fragment>
                                         {courses.singleLessonAssignments.map(
                                           (data, index) => (
                                             <a
                                               href={IMAGE_URL_ENDPOINT + data.pdffile}
                                               target='_blank'
                                               rel='noopener noreferrer'
                                               key={index}
                                             >
                                               <li className='assignment-list'>
                                                 {data.title}
                                               </li>
                                             </a>
                                           )
                                         )}
                                         <li
                                           className={
                                             toggleAddContentButton
                                               ? 'assignment-list add-assignment disable-button'
                                               : 'assignment-list add-assignment'
                                           }
                                         >
                                           {toggleAddAssignmentButton ? (
                                             <div
                                               style={{
                                                 textAlign: 'center',
                                               }}
                                             >
                                               <Icofont
                                                 icon='spinner'
                                                 size='3'
                                                 spin='true'
                                               />
                                             </div>
                                           ) : (
                                             <>
                                               <Icofont icon='plus' /> Upload Assignment
                                               <input
                                                 type='file'
                                                 className='content-file'
                                                 id='contentInput'
                                                 name='assignmentfile'
                                                 onChange={(e) => onChangeFile(e)}
                                               />
                                             </>
                                           )}
                                         </li>
                                       </Fragment>
                                     ) : (
                                       <li
                                         className={
                                           toggleAddAssignmentButton
                                             ? 'assignment-list add-assignment disable-button'
                                             : 'assignment-list add-assignment'
                                         }
                                       >
                                         {toggleAddAssignmentButton ? (
                                           <div
                                             style={{
                                               textAlign: 'center',
                                             }}
                                           >
                                             <Icofont
                                               icon='spinner'
                                               size='3'
                                               spin='true'
                                             />
                                           </div>
                                         ) : (
                                           <>
                                             <Icofont icon='plus' /> Upload Assignment
                                             <input
                                               type='file'
                                               className='content-file'
                                               id='contentInput'
                                               name='assignmentfile'
                                               onChange={(e) => onChangeFile(e)}
                                             />
                                           </>
                                         )}
                                       </li>
                                     )}
                                   </Fragment>
                                 ) : courses.singleLessonAssignmentsLoading ? (
                                   <div
                                     style={{
                                       textAlign: 'center',
                                     }}
                                   >
                                     <Icofont icon='spinner' size='3' spin='true' />
                                   </div>
                                 ) : null} */}

                        {/* <li className='quiz-list add-quiz'>
                                       <Icofont icon='plus' /> Add Assignment
                                     </li>
                                   </Fragment>
                                 ) : courses.singleLessonAssignmentsLoading ? (
                                   <div
                                     style={{
                                       textAlign: 'center',
                                     }}
                                   >
                                     <Icofont icon='spinner' size='3' spin='true' />
                                   </div>
                                 ) : null} */}
                        {/* <li className='assignment-list'>Assignment #1</li>
                                 <li className='assignment-list'>Assignment #2</li>
                                 <li className='assignment-list add-assignment'>
                                   <Icofont icon='plus' /> Add Assignment
                                 </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className='row contents-section'>
                    <div className='col-md-12'>
                      <h4 className='contents-title'>Content</h4>
                    </div>
                    <div className='col-md-12'>
                      <ul
                        className='contents-list list-group'
                        // style={{ flexDirection: 'row' }}
                      >
                        <DragDropContext onDragEnd={(e) => onDragContentEnd(e)}>
                          <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                              <ul
                                className='contents-list list-group'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                // style={{ flexDirection: 'row' }}
                              >
                                {courses.singleLessonContent !== null &&
                                !courses.singleLessonContentLoading ? (
                                  <Fragment>
                                    {Contents !== null ? (
                                      <Fragment>
                                        {loadingReorderingContents ? (
                                          <div className='overlay'>
                                            <Icofont
                                              icon='spinner'
                                              size='3'
                                              spin='true'
                                            />
                                          </div>
                                        ) : null}
                                        {Contents.map((data, index) => (
                                          <Draggable
                                            key={data._id}
                                            draggableId={data._id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              //   <a
                                              //     ref={provided.innerRef}
                                              //     {...provided.draggableProps}
                                              //     {...provided.dragHandleProps}
                                              //     href={data.pdffile}
                                              //     //   {IMAGE_URL_ENDPOINT +  data.pdffile}
                                              //     target='_blank'
                                              //     rel='noopener noreferrer'
                                              //     key={index}
                                              //     style={{ position: 'relative' }}
                                              //     className='testContent'
                                              //   >
                                              //   <div

                                              //   className='testContent'

                                              //   style={{ position: 'relative' }}
                                              //   >
                                              <li
                                                className='content-list testContent list-group-item change-cursor'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                // style={{ cursor: 'auto' }}
                                                key={index}
                                              >
                                                <a
                                                  href={data.pdffile}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                >
                                                  {' '}
                                                  {data.title}
                                                </a>

                                                {/* {data.question} */}
                                                <Icofont
                                                  icon='close'
                                                  size='1.5'
                                                  className='delete-content'
                                                  onClick={(e) =>
                                                    deleteContent(e, data._id)
                                                  }
                                                  //  onClick={(e) => changeToggleUI(e, 'courseName')}
                                                />
                                              </li>
                                              //   </div>
                                              //   </a>
                                            )}
                                          </Draggable>
                                          // </div>

                                          // <li
                                          //   className='quiz-list'
                                          //   key={index}
                                          //   onClick={() => setModalShow(true)}
                                          // >
                                          //   {data.question}
                                          // </li>
                                        ))}
                                      </Fragment>
                                    ) : null}

                                    {/* <li
                                      className={
                                        toggleAddContentButton
                                          ? 'content-list add-content disable-button'
                                          : 'content-list add-content'
                                      }
                                      onClick={() => setContentModalShow(true)}
                                    >
                                      {toggleAddContentButton ? (
                                        <div
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Icofont
                                            icon='spinner'
                                            size='3'
                                            spin='true'
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          <Icofont icon='plus' /> Add Content
                                        </>
                                      )}
                                    </li> */}
                                  </Fragment>
                                ) : courses.singleLessonContentLoading ? (
                                  <div
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Icofont
                                      icon='spinner'
                                      size='3'
                                      spin='true'
                                    />
                                  </div>
                                ) : null}
                                {provided.placeholder}
                                {/* {
                                   courses.singleLessonContent !== null &&
                                   !courses.singleLessonContentLoading ? (
                                     <Fragment>
                                       {courses.singleLessonContent.length >= 1 ? (
                                         <Fragment>
                                           {courses.singleLessonContent.map(
                                             (data, index) => (
                                               <a
                                                 href={IMAGE_URL_ENDPOINT + data.pdffile}
                                                 target='_blank'
                                                 rel='noopener noreferrer'
                                                 key={index}
                                                 style={{ position: 'relative' }}
                                                 className='testContent'
                                               >
                                                 <li className='content-list'>
                                                   {data.title}
                                                 </li>
                                                 <Icofont
                                                   icon='close'
                                                   size='1.5'
                                                   className='delete-content'
                                                   onClick={(e) =>
                                                     deleteContent(e, data._id)
                                                   }
                                                 />
                                               </a>
                                             )
                                           )}
                                           <li
                                             className={
                                               toggleAddContentButton
                                                 ? 'content-list add-content disable-button'
                                                 : 'content-list add-content'
                                             }
                                           >
                                             {toggleAddContentButton ? (
                                               <div
                                                 style={{
                                                   textAlign: 'center',
                                                 }}
                                               >
                                                 <Icofont
                                                   icon='spinner'
                                                   size='3'
                                                   spin='true'
                                                 />
                                               </div>
                                             ) : (
                                               <>
                                                 <Icofont icon='plus' /> Upload Content
                                                 <input
                                                   type='file'
                                                   className='content-file'
                                                   id='contentInput'
                                                   name='contentfile'
                                                   onChange={(e) => onChangeFile(e)}
                                                 />
                                               </>
                                             )}
                                           </li>
                                         </Fragment>
                                       ) : (
                                         <li
                                           className={
                                             toggleAddContentButton
                                               ? 'content-list add-content disable-button'
                                               : 'content-list add-content'
                                           }
                                         >
                                           {toggleAddContentButton ? (
                                             <div
                                               style={{
                                                 textAlign: 'center',
                                               }}
                                             >
                                               <Icofont
                                                 icon='spinner'
                                                 size='3'
                                                 spin='true'
                                               />
                                             </div>
                                           ) : (
                                             <>
                                               <Icofont icon='plus' /> Upload Content
                                               <input
                                                 type='file'
                                                 className='content-file'
                                                 id='contentInput'
                                                 name='contentfile'
                                                 onChange={(e) => onChangeFile(e)}
                                               />
                                             </>
                                           )}
                                         </li>
                                       )}
                                     </Fragment>
                                   ) : courses.singleLessonContentLoading ? (
                                     <div
                                       style={{
                                         textAlign: 'center',
                                       }}
                                     >
                                       <Icofont icon='spinner' size='3' spin='true' />
                                     </div>
                                   ) : null
                                 } */}
                                {/* <li className='content-list'>PDF #1</li>
                                 <li className='content-list'>PDF #2</li>
                                 <li className='content-list add-content'>
                                   <Icofont icon='plus' /> Add Content
                                 </li> */}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {courses.singleLessonContent !== null &&
                        !courses.singleLessonContentLoading ? (
                          <li
                            className={
                              toggleAddContentButton
                                ? 'content-list add-content disable-button'
                                : 'content-list add-content'
                            }
                            onClick={() => setContentModalShow(true)}
                          >
                            {toggleAddContentButton ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <Icofont icon='spinner' size='3' spin='true' />
                              </div>
                            ) : (
                              <>
                                <Icofont icon='plus' /> Add Content
                              </>
                            )}
                            {/* <Icofont icon='plus' /> Add Quiz */}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='custom-spin'>
              <Icofont icon='spinner-alt-2' size='6' spin='true' />
            </div>
          )}
        </Fragment>
      ) : (
        <div className='custom-spin'>
          <Icofont icon='spinner-alt-2' size='6' spin='true' />
        </div>
      )}

      {/* Add Quiz Modal */}

      <Modal
        show={modalShow}
        onHide={() => {
          setQuizData({
            name: '',
            link: '',
          });
          setToggleUpdateQuizButton(false);
          setSingleQuizContent('');
          setModalShow(false);
        }}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding Quiz
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='needs-validation'
            //   onSubmit={(e) => onSubmitForm(e)}
            //   onReset={(e) => onResetForm(e)}
          >
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Name *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='name'
                  value={quizData.name}
                  onChange={(e) => onChangeQuizFields(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                />
                {/* <div className="invalid-feedback">
                                                         Please enter a valid password
                                                     </div> */}
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Content *
              </label>
              <div className='col-lg-8'>
                {/* <input
                  type='text'
                  name='link'
                  value={quizData.link}
                  onChange={(e) => onChangeQuizFields(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                /> */}
                {/* <EditorToolbar />
                <ReactQuill
                  value={textQuill}
                  //   modules={{
                  //     toolbar: [['task-list']],
                  //     'task-list': true,
                  //   }}
                  onChange={(e) => textReactQuill(e)}
                  placeholder={'Write something awesome...'}
                  modules={modules}
                  formats={formats}
                /> */}
                <SunEditor
                  onChange={(e) => onChangeEditorField(e)}
                  value={quizDataLink}
                  //   name="link"
                  width='100%'
                  height='500'
                  setContents={singleQuizContent}
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      ['paragraphStyle', 'blockquote'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['fontColor', 'hiliteColor', 'textStyle'],
                      ['removeFormat'],
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'lineHeight'],
                      ['table', 'link', 'image', 'video'], // You must add the 'katex' library at options to use the 'math' plugin.
                      // ['imageGallery'], // You must add the "imageGalleryUrl".
                      ['fullScreen', 'showBlocks', 'codeView'],
                      ['preview', 'print'],
                      // ['save', 'template'],
                      // '/', Line break
                    ],
                  }}
                />
                {/* <div className="invalid-feedback">
                                                         Please enter a valid password
                                                     </div> */}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className='btn btn-success'
            onClick={(e) => testSubmitReactQuill(e)}
          >
            Test Submit
          </button> */}
          {toggleUpdateQuizButton ? (
            <button className='btn btn-success' onClick={(e) => updateQuiz(e)}>
              Update Quiz
            </button>
          ) : (
            <button className='btn btn-success' onClick={(e) => addNewQuiz(e)}>
              Save
            </button>
          )}

          <button
            className='btn btn-danger'
            onClick={() => {
              setQuizData({
                name: '',
                link: '',
              });
              setModalShow(false);
              setSingleQuizContent('');
              setToggleUpdateQuizButton(false);
              //   document.getElementById('thumbnailFile').value = null;
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Assignment Modal */}

      <Modal
        show={AssignmentModalShow}
        onHide={() => {
          setAssignmentName('');
          setSingleAssignmentContent('');
          setToggleUpdateAssignmentButton(false);
          setAssignmentModalShow(false);
        }}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding Assignment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='needs-validation'>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Name *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='name'
                  value={assignmentName}
                  onChange={(e) => onChangeAssignmentNameField(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Content *
              </label>
              <div className='col-lg-8'>
                <SunEditor
                  onChange={(e) => onChangeAssignmentEditorField(e)}
                  value={assignmentDataLink}
                  //   name="link"
                  width='100%'
                  height='500'
                  setContents={singleAssignmentContent}
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      ['paragraphStyle', 'blockquote'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['fontColor', 'hiliteColor', 'textStyle'],
                      ['removeFormat'],
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'lineHeight'],
                      ['table', 'link', 'image', 'video'], // You must add the 'katex' library at options to use the 'math' plugin.
                      // ['imageGallery'], // You must add the "imageGalleryUrl".
                      ['fullScreen', 'showBlocks', 'codeView'],
                      ['preview', 'print'],
                      // ['save', 'template'],
                      // '/', Line break
                    ],
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {toggleUpdateAssignmentButton ? (
            <button
              className='btn btn-success'
              onClick={(e) => updateAssignment(e)}
            >
              Update Assignment
            </button>
          ) : (
            <button
              className='btn btn-success'
              onClick={(e) => addNewAssignment(e)}
            >
              Save
            </button>
          )}

          <button
            className='btn btn-danger'
            onClick={() => {
              setAssignmentModalShow(false);

              setAssignmentName('');
              setSingleAssignmentContent('');
              setToggleUpdateAssignmentButton(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={AssignmentModalShow}
        onHide={() => setAssignmentModalShow(false)}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding Assignment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='needs-validation'>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Name *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='name'
                  value={assignmentData.name}
                  onChange={(e) => onChangeAssignmentFields(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Upload File *
              </label>
              <div className='col-lg-8'>
                <input
                  type='file'
                  name='assignmentFile'
                  id='assignmentFile'
                  //   value={assignmentData.link}
                  onChange={(e) => onChangeAssignmentFields(e)}
                  //   className='form-control'
                  //   placeholder='Name'
                  required='required'
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-success'
            onClick={(e) => addNewAssignment(e)}
          >
            Save
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              setAssignmentModalShow(false);

              setAssignmentData({
                name: '',
                assignmentFile: null,
              });

              document.getElementById('assignmentFile').value = null;
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal> */}

      {/* Edit Description Modal */}

      <Modal
        show={editCourseDescriptionModalShow}
        onHide={() => {
          setEditCourseDescriptionModalShow(false);
        }}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Course Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='needs-validation'>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Description *
              </label>
              <div className='col-lg-8'>
                <textarea
                  name='editDescription'
                  value={editData.description}
                  onChange={(e) => onChangeEditCourseDescription(e)}
                  className='form-control'
                  placeholder='Description'
                  style={{
                    minHeight: '200px',
                    maxHeight: 'fit-content',
                  }}
                  //   required='required'
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-success'
            onClick={(e) => edit_course_name(e, 'description')}
          >
            Update
          </button>

          <button
            className='btn btn-danger'
            onClick={() => {
              setEditCourseDescriptionModalShow(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit Description Modal */}

      {/* Add Content Modal */}

      <Modal
        show={ContentModalShow}
        onHide={() => setContentModalShow(false)}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding Content
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='needs-validation'>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Name *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='name'
                  value={contentData.name}
                  onChange={(e) => onChangeContentFields(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Upload File *
              </label>
              <div className='col-lg-8'>
                <input
                  type='file'
                  name='contentFile'
                  id='contentFile'
                  //   value={assignmentData.link}
                  onChange={(e) => onChangeContentFields(e)}
                  //   className='form-control'
                  //   placeholder='Name'
                  required='required'
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-success' onClick={(e) => addNewContent(e)}>
            Save
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              setContentModalShow(false);

              setContentData({
                name: '',
                contentFile: null,
              });

              document.getElementById('contentFile').value = null;
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Video Modal */}

      <Modal
        show={AddNewVideoUI}
        onHide={() => {
          setAddNewVideoUI(false);

          setVideoData({
            title: '',
            videoDescription: '',
            videoFile: null,
          });

          document.getElementById('videoFile').value = null;
        }}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding New Video
          </Modal.Title>
        </Modal.Header>
        <form className='needs-validation' onSubmit={(e) => addNewVideo(e)}>
          <Modal.Body>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Title *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='title'
                  value={videoData.title}
                  onChange={(e) => onChangeVideoFields(e)}
                  className='form-control'
                  placeholder='Title'
                  required='required'
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Description *
              </label>
              <div className='col-lg-8'>
                <textarea
                  type='text'
                  name='videoDescription'
                  value={videoData.videoDescription}
                  onChange={(e) => onChangeVideoFields(e)}
                  className='form-control'
                  placeholder='Description'
                  required='required'
                />
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Upload File *
              </label>
              <div className='col-lg-8'>
                <input
                  type='file'
                  accept='.mp4,.MOV,.m4v'
                  name='videoFile'
                  id='videoFile'
                  //   value={assignmentData.link}
                  onChange={(e) => onChangeVideoFields(e)}
                  //   className='form-control'
                  //   placeholder='Name'
                  required='required'
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-success' type='submit'>
              Save
            </button>
            <button
              className='btn btn-danger'
              onClick={() => {
                setAddNewVideoUI(false);

                setVideoData({
                  title: '',
                  videoDescription: '',
                  videoFile: null,
                });

                document.getElementById('videoFile').value = null;
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Add Existing Video Modal */}

      <Modal
        show={AddExistingVideoUI}
        onHide={() => {
          setModalLessonId('');
          setAddExistingVideoUI(false);
        }}
        size='lg'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Adding Video
          </Modal.Title>
        </Modal.Header>
        <form
          className='needs-validation'
          onSubmit={(e) => {
            createExistingVideo(e);
          }}
          //   onReset={(e) => onResetForm(e)}
        >
          <Modal.Body>
            {/* <form
            className='needs-validation'
            //   onSubmit={(e) => onSubmitForm(e)}
            //   onReset={(e) => onResetForm(e)}
          > */}
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Lessons *
              </label>
              <div className='col-lg-8'>
                <div className='select'>
                  {courses.LessonListData !== null ? (
                    <select
                      // name='category'
                      className='custom-select form-control'
                      value={modalLessonId}
                      required='required'
                      onChange={(e) => {
                        setModalLessonId(e.target.value);
                        getDetailForSingleLesson(e.target.value, 'modal');
                      }}
                    >
                      <option value='' disabled={true}>
                        Select an option
                      </option>
                      {courses.LessonListData.lessons.map((data, i) => (
                        <option key={i} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      name='category'
                      className='custom-select form-control'
                      //   value={data.category}
                      required=''
                      //   onChange={(e) => onChangeFields(e)}
                      disabled={true}
                    >
                      <option value='' disabled={true}>
                        Select an option
                      </option>
                    </select>
                  )}

                  {/* <div className="invalid-feedback">
                                                            Please select an option
                                                        </div> */}
                </div>
              </div>
            </div>
            <div className='form-group row d-flex align-items-center mb-5'>
              <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Videos
              </label>
              <div className='col-lg-8'>
                {courses.singleModalLessonVideos !== null &&
                modalLessonId !== '' ? (
                  <Fragment>
                    {courses.singleModalLessonVideos.length <= 0 ? (
                      'No Videos Found'
                    ) : (
                      <Fragment>
                        {courses.singleModalLessonVideos.map((data, i) => (
                          <div className='mb-3' key={i}>
                            <div className='styled-radio'>
                              <input
                                type='radio'
                                name='radio'
                                value={data.id}
                                // checked={data._id === videoId ? true : false}
                                id={'rad-' + i}
                                onChange={(e) => {
                                  setVideoId(e.target.value);
                                }}
                                required='required'
                              />
                              <label htmlFor={'rad-' + i}>
                                {data.video_title}
                              </label>
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  //   <div
                  //     style={{
                  //       textAlign: 'center',
                  //     }}
                  //   >
                  <Fragment>
                    {modalLessonId !== '' ? (
                      <Icofont icon='spinner' size='3' spin='true' />
                    ) : null}
                  </Fragment>
                  //   </div>
                )}

                {/* <div className='mb-3'>
                  <div className='styled-radio'>
                    <input type='radio' name='radio' id='rad-2' />
                    <label htmlFor='rad-2'>Video 2</label>
                  </div>
                </div>
                <div className='mb-3'>
                  <div className='styled-radio'>
                    <input type='radio' name='radio' id='rad-3' />
                    <label htmlFor='rad-3'>Video 2</label>
                  </div>
                </div>
                <div className='mb-3'>
                  <div className='styled-radio'>
                    <input type='radio' name='radio' id='rad-4' />
                    <label htmlFor='rad-4'>Checked and Disabled</label>
                  </div>
                </div> */}
              </div>
              {/* <label className='col-lg-2 form-control-label d-flex justify-content-lg-end'>
                Link *
              </label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  name='link'
                  value={quizData.link}
                  onChange={(e) => onChangeQuizFields(e)}
                  className='form-control'
                  placeholder='Name'
                  required='required'
                />
               
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='btn btn-success'
              // onClick={(e) => {
              //   createExistingVideo(e);
              // }}
              type='submit'
            >
              Save
            </button>
            <button
              className='btn btn-danger'
              onClick={() => {
                setModalLessonId('');
                setAddExistingVideoUI(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Image Change Confirmation Modal */}

      <Modal
        show={toggleThumbnailConfirm}
        onHide={() => {
          setToggleTumbnailConfirm(false);
          document.getElementById('thumbnailFile').value = null;
        }}
        size='md'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}
          <p>Are you sure want to change an image?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-success'
            onClick={(e) => edit_course_name(e, 'image')}
          >
            Yes
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              setToggleTumbnailConfirm(false);
              document.getElementById('thumbnailFile').value = null;
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* <div className='row animated zoomIn'> */}

      {/* <div className='container-fluid'>
          
      </div> */}
    </div>
    // </div>
  );
}

DetailCourse.propTypes = {
  toggling: PropTypes.object.isRequired,
  getLessonsOfSingleCourse: PropTypes.func.isRequired,
  getDetailForSingleLesson: PropTypes.func.isRequired,
  createLesson: PropTypes.func.isRequired,
  createVideoForSingleLesson: PropTypes.func.isRequired,
  editVideoForSingleLesson: PropTypes.func.isRequired,
  createContentForSingleLesson: PropTypes.func.isRequired,
  editCourseName: PropTypes.func.isRequired,
  createNewQuiz: PropTypes.func.isRequired,
  getSingleRecordQuiz: PropTypes.func.isRequired,
  getSingleRecordAssignment: PropTypes.func.isRequired,
  createAssignmentForSingleLesson: PropTypes.func.isRequired,
  delete_quiz: PropTypes.func.isRequired,
  delete_content: PropTypes.func.isRequired,
  delete_assignment: PropTypes.func.isRequired,
  delete_video: PropTypes.func.isRequired,
  delete_lesson: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  editLessonName: PropTypes.func.isRequired,
  updateQuizContent: PropTypes.func.isRequired,
  updateAssignmentContent: PropTypes.func.isRequired,
  reorderingLessons: PropTypes.func.isRequired,
  reorderingVideos: PropTypes.func.isRequired,
  reorderingContents: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  toggling: state.toggling,
  courses: state.courses,
});

export default connect(mapStateToProps, {
  getLessonsOfSingleCourse,
  getDetailForSingleLesson,
  createLesson,
  createVideoForSingleLesson,
  editVideoForSingleLesson,
  createContentForSingleLesson,
  createAssignmentForSingleLesson,
  getSingleRecordAssignment,
  editCourseName,
  createNewQuiz,
  getSingleRecordQuiz,
  delete_quiz,
  delete_content,
  delete_assignment,
  delete_video,
  delete_lesson,
  sendNotification,
  editLessonName,
  updateQuizContent,
  updateAssignmentContent,
  reorderingLessons,
  reorderingVideos,
  reorderingContents,
})(DetailCourse);

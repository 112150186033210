import {
  ADMIN_API_ENDPOINT,
  AUTH_ERROR,
  CREATE_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  GET_SINGLE_ASSIGNMENT,
  UPDATE_ASSIGNMENT_LOADING,
  UPDATE_ASSIGNMENT,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// CREATE Assignment

export const createAssignmentForSingleLesson = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    // dispatch({
    //   type: LOADING_CONTENT,
    // });

    const res = await axios.post(
      `${ADMIN_API_ENDPOINT}/assignment/`,
      data,
      config
    );

    // // // console.log('Create Assignment For Single Lesson Response: ', res.data);

    dispatch({
      type: CREATE_ASSIGNMENT,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Assignment Created',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });
  } catch (error) {
    // // // console.log('Create Assignment For Single Lesson  error: ', error);

    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: 'Assignment Not Created',
      timeout: 3000,
    }).show();
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// Get Single Record Assignment

export const getSingleRecordAssignment = (id) => async (dispatch) => {
  // const config = {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  // };

  try {
    const res = await axios.get(`${ADMIN_API_ENDPOINT}/assignment/${id}/`);

    // // // console.log('GET SINGLE Assignment Response: ', res.data);

    await dispatch({
      type: GET_SINGLE_ASSIGNMENT,
      payload: res.data,
    });

    //   new Noty({
    //     type: 'success',
    //     layout: 'bottomCenter',
    //     text: 'Quiz Created',
    //     timeout: 3000,
    //   }).show();

    // if (res.data.description )
    return {
      data: res.data,
      status: true,
    };
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('GET SINGLE Assignment Network Error: ',error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('GET SINGLE Assignemnt Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.message,
        timeout: 3000,
      }).show();
    }
  }
};

// Update Assignment

export const updateAssignmentContent = (data, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    dispatch({
      type: UPDATE_ASSIGNMENT_LOADING,
      payload: true,
    });

    const res = await axios.patch(
      `${ADMIN_API_ENDPOINT}/assignment/${id}/`,
      data,
      config
    );

    // // // console.log('Update Assignment Response: ', res.data);

    const updatedData = {
      ID: id,
      title: data.get('title'),
      pdffile: data.get('pdffile'),
    };

    dispatch({
      type: UPDATE_ASSIGNMENT,
      payload: updatedData,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Assignment Updated',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Update Assignment Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
      dispatch({
        type: UPDATE_ASSIGNMENT_LOADING,
        payload: false,
      });
    } else {
      // // // console.log('Update Assignment Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.message,
        timeout: 3000,
      }).show();
      dispatch({
        type: UPDATE_ASSIGNMENT_LOADING,
        payload: false,
      });
    }
  }
};

// Delete Assignment

export const delete_assignment = (data, id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/assignment/${id}/?lesson_id=${data.get(
        'lesson_id'
      )}&course_id=${data.get('course_id')}`
    );

    // // // console.log('delete assignment Response: ', res.data);

    let check = await dispatch({
      type: DELETE_ASSIGNMENT,
      payload: id,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Assignment Deleted',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    // // // console.log('sadasdsad', check);
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete assignment Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: 'Network Error',
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Delete assignment Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

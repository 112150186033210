import {
  ADMIN_API_ENDPOINT,
  UPDATE_CONTENT,
  AUTH_ERROR,
  DELETE_CONTENT,
} from './types';
import axios from 'axios';
import Noty from 'noty';

// CREATE Content

export const createContentForSingleLesson = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    // dispatch({
    //   type: LOADING_CONTENT,
    // });

    const res = await axios.post(
      `${ADMIN_API_ENDPOINT}/courseContent/`,
      data,
      config
    );

    // // // console.log('Create Content For Single Lesson Response: ', res.data);

    dispatch({
      type: UPDATE_CONTENT,
      payload: res.data,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Content Created',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
    // dispatch({
    //   type: GET_COURSES,
    //   payload: res.data,
    // });
  } catch (error) {
    // // // console.log('Create Content For Single Lesson  error: ', error);

    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: 'Content Not Created',
      timeout: 3000,
    }).show();
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

// DELETE CONTENT

export const delete_content = (data, id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${ADMIN_API_ENDPOINT}/courseContent/${id}/?lesson_id=${data.get(
        'lesson_id'
      )}&course_id=${data.get('course_id')}`
    );

    // // // console.log('delete Content Response: ', res.data);

    dispatch({
      type: DELETE_CONTENT,
      payload: id,
    });

    new Noty({
      type: 'success',
      layout: 'bottomCenter',
      text: 'Content Deleted',
      timeout: 3000,
    }).show();

    // if (res.data.description )
    return true;
  } catch (error) {
    // // // // console.log(error);
    if (error.message !== 'Network Error') {
      // // // console.log('Delete Content Network Error: ', error.response.status);
      if (error.response.status === 401) {
        dispatch({
          type: AUTH_ERROR,
        });
      } else {
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }
    } else {
      // // // console.log('Delete Content Error: ', error);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.response.data.description,
        timeout: 3000,
      }).show();
    }
  }
};

// Reordering Contents

export const reorderingContents =
  (data, lesson_id, course_id) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const sendData = {
      lesson_id: lesson_id,
      course_id: course_id,
      content: data,
    };

    try {
      const res = await axios.patch(
        `${ADMIN_API_ENDPOINT}/courseContent/reordering/`,
        sendData,
        config
      );

      // // // console.log('Reordering Contents Response: ', res.data);

      //   dispatch({
      //     type: CREATE_QUIZ,
      //     payload: res.data,
      //   });

      dispatch({
        type: UPDATE_CONTENT,
        payload: res.data,
      });

      // new Noty({
      //   type: 'success',
      //   layout: 'bottomCenter',
      //   text: 'Notification Send',
      //   timeout: 3000,
      // }).show();

      //   dispatch({
      //     type: GET_COURSES,
      //     payload: res.data,
      //   });

      // if (res.data.description )
      return true;
    } catch (error) {
      if (error.message !== 'Network Error') {
        // // // console.log('Reordering Videos Network Error: ', error.response.status);
        if (error.response.status === 401) {
          dispatch({
            type: AUTH_ERROR,
          });
        } else {
          new Noty({
            type: 'error',
            layout: 'bottomCenter',
            text: error.response.data.description,
            timeout: 3000,
          }).show();
        }
      } else {
        // // // console.log('Reordering Videos Error: ', error);
        new Noty({
          type: 'error',
          layout: 'bottomCenter',
          text: error.response.data.description,
          timeout: 3000,
        }).show();
      }

      return false;
    }
  };

import {
  GET_ALL_USERS,
  GET_ALL_USERS_COUNT,
  GET_ROLES,
  UPDATE_USER,
} from '../actions/types';

const initialState = {
  all_users: null,
  all_users_count: null,
  roles: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS:
      return {
        ...state,
        all_users: payload,
      };
    case GET_ALL_USERS_COUNT:
      return {
        ...state,
        all_users_count: payload,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: payload,
      };

    case UPDATE_USER:
      if (payload.type_check === 'account_status') {
        state.all_users.forEach(function (obj) {
          if (obj.id === payload.id) {
            //change the value here
            obj.account_status = payload.account_status;
            return true; //breaks out of he loop
          }
        });
      } else {
        state.all_users.forEach(function (obj) {
          if (obj.id === payload.id) {
            //change the value here
            obj.role.id = payload.role;
            return true; //breaks out of he loop
          }
        });
      }

      return {
        ...state,
      };

    default:
      return state;
  }
}
